import axios from 'axios';
import {  API_URL } from '../config/env';


const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const VccService = {

    AddCard(id,cardData){
        const token = getToken();
        return axios.post(`${API_URL}/admin/cards/vcc/create/${id}`, cardData, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        }); 
    },

    getUsersWithPaycard: () => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/cards/vcc/manual/list/user`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    AddCardManually: (cardData) => {
        const token = getToken();
        return axios.post(`${API_URL}/admin/cards/vcc/manual/add`,cardData, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    ListCards: (page) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/cards/vcc?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getVccCard(id){
        const token = getToken();
        return axios.get(`${API_URL}/admin/cards/vcc/${id}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        }); 
    },
    
    getCardProviderType(){
        const token = getToken();
        return axios.get(`${API_URL}/admin/parameter/card-type`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        }); 
    },
};