import React,{Fragment, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Dropdown, Modal } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import TransferOwl from './../Mophy/Dashboard/TransferOwl';
import TransferBarGraph from './../Mophy/Dashboard/TransferBarGraph';
import HomeTab from './../Mophy/Dashboard/HomeTab';
import Donut from './../Mophy/Dashboard/Donut';

//Images
import card1 from './../../../images/card/card.png';
import dual from './../../../images/dual-dot.png';
import { WalletService } from '../../../services/WalletService';
import { StatisticsService } from '../../../services/StatisticsService';
import { subscribeToPush } from '../../../services/subscribeToPush';
import { getUserFromLocalStorage } from '../../../store/actions/AuthActions';


const LimitChart = loadable(() =>
	pMinDelay(import("./../Mophy/Dashboard/LimitChart"), 1000)
);
const IncomeChart = loadable(() =>
	pMinDelay(import("./../Mophy/Dashboard/IncomeChart"), 1000)
);
const OutomeChart = loadable(() =>
	pMinDelay(import("./../Mophy/Dashboard/OutomeChart"), 1000)
);
const WeeklyChart = loadable(() =>
	pMinDelay(import("./../Mophy/Dashboard/WeeklyChart"), 1000)
);


const Home = () => {

	const [walletData, setWalletData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
	const [sendMessage, setSendMessage] = useState(false);

	const [userMetric, setUserMetric] = useState();

	const [cardMetric, setCardRMetric] = useState();

	const [cardMetricpending, setCardRMetricpending] = useState();
	const [cardMetricsuccess, setCardRMetricsuccess] = useState();

	const [DepositRMetric, setDepositRMetric] = useState();

	const [cardPercentage, setCardPercentage] = useState();

	const [activeCardPercentage, setActiveCardPercentage] = useState();


	const [depositMetricpending, setDepositMetricpending] = useState();
	const [depositMetricsuccess, setDepositMetricsuccess] = useState();

	const [percentageDeposit, setDepositPercentage] = useState();

	const [depositPercentageSuccess, setDepositPercentageSuccess] = useState();

	const [topupMetric, setTopupMetric] = useState();

	const [topupMetricpending, setTopupMetricpending] = useState();
	const [topupMetricsuccess, setTopupMetricsuccess] = useState();

	const [percentageTopup, setTopupPercentage] = useState(0);

	
	const [topupPercentageSuccess, setTopupPercentageSuccess] = useState(0);

    const navigate=useNavigate();
  const [isSubscribed, setIsSubscribed] = useState(false);
	const [user, setUser] = useState(null);

	useEffect(() => {
	  const userData = getUserFromLocalStorage();
	  setUser(userData); // Set the user info in the state
	}, []);
  const handleSubscription = async () => {
    // try {
	console.log("permission",Notification.permission);
    //   if (Notification.permission === "denied") {

    //     alert("You have blocked notifications. Please enable them in your browser settings.");
    //     return;
    //   }
	  const isConfirmed = window.confirm("Are you sure you want to save this edit?");

console.log("confirmate",isConfirmed)
	  if (isConfirmed) {
		console.log("confirmate in",isConfirmed)
		Notification.requestPermission().then(async function() {
		await subscribeToPush(user.email);
		});
		console.log("await",isConfirmed)
		setIsSubscribed(true);
		}
		console.log("confirmate",isConfirmed)
    // } catch (error) {
    //   console.error("Error while subscribing to push notifications:", error);
    // }
  };

    useEffect(() => {
            WalletService.getMasterBalance()
                .then((response) => {
                    const { data } = response;
                        setWalletData(data.balance); // Set the first item from the array
                })
                .catch(error => {
                    console.error('Error fetching card data:', error);
                    setError(error);
                })
    
    }, []);

    useEffect(() => {
			StatisticsService.getUserMetric()
				.then((response) => {
					const { data } = response;
						setUserMetric(data.data.userAll); // Set the first item from the array
				})
				.catch(error => {
					console.error('Error fetching card data:', error);
					setError(error);
				})

	}, []);

	useEffect(() => {
		StatisticsService.getCardRequestMetric()
			.then((response) => {
				const { data } = response;
					
					setCardRMetric(data.data.cardRequestAll); // Set the first item from the array
					setCardRMetricsuccess(data.data.cardRequestSuccess);
					setCardRMetricpending(data.data.cardRequestPending);

					// const percentage = cardMetric !== 0 ? (cardMetricpending / cardMetric) * 100 : 0;

					// const percentagesuccess = cardMetric !== 0 ? (cardMetricsuccess / cardMetric) * 100 : 0;

					// setCardPercentage(percentage.toFixed(2));

					// setActiveCardPercentage(percentagesuccess.toFixed(2));

			})
			.catch(error => {
				console.error('Error fetching card data:', error);
				setError(error);
			})

	}, []);
	useEffect(() => {
		if (cardMetric !== 0) {
			const percentage = (cardMetricpending / cardMetric) * 100;
			const percentagesuccess = (cardMetricsuccess / cardMetric) * 100;
	
			setCardPercentage(percentage.toFixed(2));
			setActiveCardPercentage(percentagesuccess.toFixed(2));
		}
	}, [cardMetric, cardMetricpending, cardMetricsuccess]);
	
	useEffect(() => {
		StatisticsService.getDepositRequestMetric()
			.then((response) => {
				const { data } = response;

					setDepositRMetric(data.data.depositRequestAll); // Set the first item from the array

					setDepositMetricsuccess(data.data.depositRequestSuccess);
					setDepositMetricpending(data.data.depositRequestPending);




			})
			.catch(error => {
				console.error('Error fetching card data:', error);
				setError(error);
			})

	}, []);

	useEffect(() => {
		if (DepositRMetric !== 0) {
			const percentageDeposit = DepositRMetric !== 0 ? (depositMetricpending / DepositRMetric) * 100 : 0;

			const percentageDepositsuccess = DepositRMetric !== 0 ? (depositMetricsuccess / DepositRMetric) * 100 : 0;

			setDepositPercentage(percentageDeposit.toFixed(2));
			
			setDepositPercentageSuccess(percentageDepositsuccess.toFixed(2));
		}
	}, [DepositRMetric, depositMetricpending, depositMetricsuccess]);
	
	useEffect(() => {
		StatisticsService.getTopupRequestMetric()
			.then((response) => {
				const { data } = response;

				setTopupMetric(data.data.topupRequestAll); // Set the first item from the array
				setTopupMetricsuccess(data.data.topupRequestSuccess);
				setTopupMetricpending(data.data.topupRequestPending);


			})
			.catch(error => {
				console.error('Error fetching card data:', error);
				setError(error);
			})

	}, []);

	useEffect(() => {
		if (topupMetric !== 0) {
			const percentage = topupMetric !== 0 ? (topupMetricpending / topupMetric) * 100 : 0;
			const percentagesuccess = topupMetric !== 0 ? (topupMetricsuccess / topupMetric) * 100 : 0;
			setTopupPercentage(percentage.toFixed(2));

			setTopupPercentageSuccess(percentagesuccess.toFixed(2));
		}
	}, [topupMetric, topupMetricpending, topupMetricsuccess]);
	
	return(
		<Fragment>
			<div className="form-head mb-4">
				<h2 className="text-black font-w600 mb-0">Dashboard</h2>
			</div>
			<div className='m-5'>

				<button className="btn btn-primary" onClick={handleSubscription}>Toggle notification</button>

			</div>
			<div className="row">
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
							<div className="card-bx stacked">
								<img src={card1} alt="" className="mw-100" />
								<div className="card-info text-white">
									<p className="mb-1">Main Balance</p>
									{walletData?.USD !== undefined ? (
											<h2 className="fs-36 text-white mb-sm-4 mb-3">{walletData.USD} USD</h2>
										) : (
											<p>NOT FOUND</p>
										)}									
									<div className="d-flex align-items-center justify-content-between mb-sm-5 mb-3">
										{/* <img src={dual} alt="" className="dot-img" /> */}
										<h4 className="fs-20 text-white mb-0"></h4>
									</div>
									<div className="d-flex">
										<div className="me-5">
											<p className="fs-14 mb-1 op6"></p>
											<span></span>
										</div>
										<div>
											<p className="fs-14 mb-1 op6"></p>
											<span>Paytaps Wallet</span>
										</div>
									</div>
								</div>
								{/* <Link to={"/cards-center"}><i className="fa fa-caret-down" aria-hidden="true"></i></Link> */}
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-5 col-sm-4">
							<div className="card bgl-primary card-body overflow-hidden p-0 d-flex rounded">
								<div className="p-0 text-center mt-3">
									<span className="text-black">Users</span>
									<h3 className="text-black fs-20 mb-0 font-w600">{userMetric}</h3>
								</div>
								<div className="mt-auto line-chart-demo">
									<LimitChart />
								</div>									
							</div>	
						</div>
						<div className="col-xl-12">
							<div className="card">
								<div className="card-header d-sm-flex d-block border-0 pb-0">
									<div className="pr-3 me-auto mb-sm-0 mb-3">
										<h4 className="fs-20 text-black mb-1">Transaction Overview</h4>
										<span className="fs-12"></span>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										{/* <Link to={"#"} className="btn btn-rounded btn-light me-3" data-toggle="modal" data-target="#DownloadReport" onClick={() => setSendMessage(true)} ><i className="las la-download text-primary scale5 me-3"></i>Download Report</Link>
										
										<Dropdown className="">
											<Dropdown.Toggle variant="" as="div" className="btn-link i-false" >	
												<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill	="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><	circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
											</Dropdown.Toggle>	
											<Dropdown.Menu className="dropdown-menu-right" alignRight={true}>
												<Dropdown.Item >Delete </Dropdown.Item>
												<Dropdown.Item >Edit </Dropdown.Item>		
											</Dropdown.Menu>			
										</Dropdown> */}
									</div>
								</div>
								<div className="card-body">
									<div id="chartBar">
										<TransferBarGraph />
									</div>
									{/* <div className="d-flex">
										<div className="form-check custom-switch toggle-switch text-right me-4 mb-2">
											<input type="checkbox" className="form-check-input" id="customSwitch11" />
											<label className="form-check-label fs-14 text-black pr-2" for="customSwitch11">Number</label>
										</div>
										<div className="form-check custom-switch toggle-switch text-right me-4 mb-2">
											<input type="checkbox" className="form-check-input" id="customSwitch12" />
											<label className="form-check-label fs-14 text-black pr-2" for="customSwitch12">Analytics</label>
										</div>
									</div> */}
								</div>
							</div>
						</div>

					</div>
				</div>
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-6 col-sm-6">
							<div className="card">
								<div className="card-header flex-wrap border-0 pb-0">
									<div className="me-3 mb-2">
										<p className="fs-14 mb-1">Card requests</p>
										<span className="fs-24 text-black font-w600">{cardMetric}</span>
									</div>
									<span className="fs-12 mb-2">

									
									</span>
								</div>
								<div className="card-body p-0">
									<IncomeChart />	
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-sm-6">
							<div className="card">
								<div className="card-header flex-wrap border-0 pb-0">
									<div className="me-3 mb-2">
										<p className="fs-14 mb-1">Deposit Requests</p>
										<span className="fs-24 text-black font-w600">{DepositRMetric}</span>
									</div>
									{/* <span className="fs-12 mb-2">
									<svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.3514 7.5C15.9974 9.37169 19.0572 12.8253 20 14H1V1L8.18919 10.75L14.3514 7.5Z" fill="url(#paint0_linear1)"/>
										<path d="M19.5 13.5C18.582 12.4157 15.6027 9.22772 14 7.5L8 10.5L1 1.5" stroke="#FF2E2E" strokeWidth="2" stroke-linecap="round"/>
										<defs>
										<linearGradient id="paint0_linear1" x1="10.5" y1="2.625" x2="9.64345" y2="13.9935" gradientUnits="userSpaceOnUse">
										<stop offset="0" stopColor="#FF2E2E"/>
										<stop offset="1" stopColor="#FF2E2E" stopOpacity="0.03"/>
										</linearGradient>
										</defs>
									</svg>
									4% (30 days)</span> */}
								</div>
								<div className="card-body p-0">
									<OutomeChart />
								</div>
							</div>
						</div>
						<div className="col-xl-12">
							<div className="card overflow-hidden">
								<div className="card-header d-sm-flex d-block border-0 pb-0">
									<div className="mb-sm-0 mb-2">
										<p className="fs-14 mb-1">Top-up Requests</p>
										<span className="mb-0">
										<svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.9999 6L5.99994 -2.62268e-07L-6.10352e-05 6" fill="#2BC155"/>
										</svg>
										<strong className="fs-24 text-black ms-2 me-3">{percentageTopup}%</strong></span>
									</div>
									<span className="fs-12">

									</span>
								</div>
								<div className="card-body p-0">
									<WeeklyChart />
								</div>
							</div>
						</div>
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row">
										<div className="col-xl-5 col-xxl-12 col-md-5">
											<h4 className="fs-20 text-black mb-4">Metrics</h4>
											<div className="row">
												<div className="d-flex col-xl-12 col-xxl-6  col-md-12 col-sm-6 mb-4">
													<svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#AC39D4"/>
													</svg>
													<div>
														<p className="fs-14 mb-2">Pending Cards</p>
														<span className="fs-18 font-w500"><span className="text-black me-2">{cardMetricpending}</span>/{cardMetric}</span>
													</div>													
												</div>
												<div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
													<svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#40D4A8"/>
													</svg>
													<div>
														<p className="fs-14 mb-2">Active Cards</p>
														<span className="fs-18 font-w500"><span className="text-black me-2">{cardMetricsuccess}</span>/{cardMetric}</span>
													</div>
												</div>
												<div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
													<svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#1EB6E7"/>
													</svg>
													<div>
														<p className="fs-14 mb-2">Pending Deposit Requests</p>
														<span className="fs-18 font-w500"><span className="text-black me-2">{depositMetricpending}</span>/{DepositRMetric}</span>
													</div>
												</div>
												<div className="d-flex col-xl-12 col-xxl-6 col-md-12 col-sm-6 mb-4">
													<svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#461EE7"/>
													</svg>
													<div>
														<p className="fs-14 mb-2">Pending Top-up Requests</p>
														<span className="fs-18 font-w500"><span className="text-black me-2">{topupMetricpending}</span>/{topupMetric}</span>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-7  col-xxl-12 col-md-7">
											<div className="row">
												<div className="col-sm-6 mb-4">
													<div className="bg-secondary rounded text-center p-3">
														<div className="d-inline-block position-relative donut-chart-sale mb-3">
															<Donut value={cardPercentage} backgroundColor="rgb(255, 255, 255)" backgroundColor2="rgba(0,0,0,0.1)"/>
															<small className="text-white">{cardPercentage}%</small>
														</div>
														<span className="fs-14 text-white d-block">Pending Cards</span>
													</div>
												</div>
												<div className="col-sm-6 mb-4">
													<div className="bg-success rounded text-center p-3">
														<div className="d-inline-block position-relative donut-chart-sale mb-3">
															<Donut value={activeCardPercentage} backgroundColor="rgb(255, 255, 255)" backgroundColor2="rgba(0,0,0,0.1)"/>
															<small className="text-white">{activeCardPercentage}%</small>
														</div>
														<span className="fs-14 text-white d-block">Active Cards</span>
													</div>
												</div>
												<div className="col-sm-6 mb-sm-0 mb-4">
													<div className="border border-2 border-primary rounded text-center p-3">
														<div className="d-inline-block position-relative donut-chart-sale mb-3">
															<Donut value={percentageDeposit} backgroundColor="rgb(30, 170, 231)" backgroundColor2="rgba(0,0,0,0.1)"/>
															<small className="text-black">{percentageDeposit}%</small>
														</div>
														<span className="fs-14 text-black d-block">Deposit</span>
													</div>
												</div>
												<div className="col-sm-6 mb-sm-0 mb-4">
													<div className="bg-info rounded text-center p-3">
														<div className="d-inline-block position-relative donut-chart-sale mb-3">
															<Donut value={percentageTopup} backgroundColor="rgb(255, 255, 255)" backgroundColor2="rgba(0,0,0,0.1)"/>
															<small className="text-white">{percentageTopup}%</small>
														</div>
														<span className="fs-14 text-white d-block">Pending Top-up</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>		

		</Fragment>
	)
}
export default Home;
