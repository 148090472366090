import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { WalletService } from '../../../services/WalletService';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../services/AuthService'; 
import {secretKey} from '../../../config/env';
import { UserService } from '../../../services/UserService';

const DepositRequestDetails = () => {
    const { Id } = useParams();
    const [depositData, setDepositData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState("Success");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (Id) {
            WalletService.getDepositRequestbyId(Id)
                 .then((response) => {
                    const { data } = response.data;
                    if (data) {
                        setDepositData(data[0]);                        
                        // Directly set the data object
                    }  else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);



    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        // Add save logic here
        setIsEditing(false);
        const isConfirmed = window.confirm("Are you sure you want to save this edit?");

        const dataToSend = {
          amount: depositData.amount,
          hashTransaction: depositData.hashTransaction,
      };

        if (isConfirmed) {
          WalletService.editRequestDeposit(Id,dataToSend)
          .then(response => {
            swal("Good job!", "data edited successfully", "success")
            // window.location.reload();
          })
          .catch(error => {
            const errorMessage = error.response?.data.message || 'An unexpected error occurred';
            console.error('Error edit:', errorMessage);
            swal("Ooops!", errorMessage, "error");
        });
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // Optionally reset form data if necessary
    };

    const handleBack = () => {
      navigate(`/${secretKey}/client-deposit-request`);
    };





    const handleApproveRequest = () => {
      const isConfirmed = window.confirm("Are you sure you want to approve this request?");
      if (isConfirmed) {
        WalletService.approveRequestDeposit(Id,{ status: approvalStatus })
        .then(response => {
          swal("Good job!", "Deposit Request Approved Successfully", response).then(() => {window.location.reload()});
          })
          
        .catch(error => {
          const errorMessage = error.response?.data.message || 'An unexpected error occurred';
          console.error('Error approving request:', errorMessage);
          swal("Ooops!", errorMessage, "error");
      });

    UserService.notify({
        title: "Deposit Request",
        message: "Your Deposit Request Has Been Approved",
        clientIdentifier: depositData.user.email, // Identifies the client to notify                     // Optional: group/tag notifications
        actions: [                               // Optional: notification actions
            { action: "open", title: "View Message" },
            { action: "dismiss", title: "Dismiss" }
        ]
    })
    .then(response => {
    })
    .catch(error => {
        const errorMessage = error.response?.data.message || "An unexpected error occurred";
        console.error("Error sending notification:", errorMessage);
    });
  
      }
  };

    return (

        <Fragment>
         

         { depositData? (
          
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Card request detail</h4>
                <button className="btn btn-secondary" type="button" onClick={handleBack}>Back to list</button>
  
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>User</label>
                        <input
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`}                        
                          type="text"
                          value={depositData.user.email}
                          disabled
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Amount</label>
                         <input
                          type="text"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={depositData.amount}
                          disabled={!isEditing}
                          onChange={(e) => setDepositData({ ...depositData, amount: e.target.value })}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Currency</label>
                         <input
                          type="text"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={depositData.currency}
                          disabled
                        />
                      </div>    
                      {depositData.hashTransaction? (                
                      <div className="form-group mb-3 col-md-6">
                        <label>Transaction Hash</label>
                         <input
                          type="text"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={depositData.hashTransaction}
                          disabled={!isEditing}
                          onChange={(e) => setDepositData({ ...depositData, hashTransaction: e.target.value })}
                        />
                      </div>) :(
                                              <div className="form-group mb-3 col-md-6">
                                              <label>UUid Account</label>
                                               <input
                                                type="text"
                                                className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                                                value={depositData.uidAccount}
                                                disabled={!isEditing}
                                                onChange={(e) => setDepositData({ ...depositData, uidAccount: e.target.value })}
                                              />
                                            </div>
                      )
                      }
                      
                  </div>
                  <div>
                  {!isEditing ? (
                          <button className="btn btn-primary" type="button" onClick={handleEditClick}>Edit</button>
                      ) : (
                          <>
                              <button className="btn btn-primary" type="button" onClick={handleSaveClick}>Save</button>
                              <button className="btn btn-primary" type="button" onClick={handleCancelClick}>Cancel</button>
                          </>
                      )}
                        {depositData.status === 'Success' ? (
                              <span className="badge bg-success" style={{ float: 'right' }}>Approved</span>
                          ) : (
                              <button className="btn btn-secondary" style={{ float: 'right' }} type="button" onClick={handleApproveRequest}>
                                  Approve Request
                              </button>
                          )}                                  
                        </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
            ):(<div className="">no data</div>)
          }


        </Fragment>
    );
};

export default DepositRequestDetails;
