// export default Rewards;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Accordion, Tab, Nav } from 'react-bootstrap';
import { IconRed, PendingBlog, TimeOutBlog, IconGreen, CompleteBlog } from './TabData';
import { UserService } from '../../../services/UserService';
import PerfectScrollbar from "react-perfect-scrollbar";
import { RewardsService } from '../../../services/RewardsService';
import moment from 'moment';
import {secretKey} from '../../../config/env';

const Rewards = () => {
    const { Id } = useParams();
    const [accordionBlog, setAccordionBlog] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    // Function to fetch data based on the page and search query
    const fetchData = () => {

      const functionconsole = (date) => {

    };

      
        RewardsService.getReferral(Id,currentPage + 1)  // Assuming API accepts search and pagination params
            .then((response) => {
                const { data, currentPageNumber, numItemsPerPage, totalCount } = response.data;
                const formattedData = data.items.map((item) => ({
                    title: item.inviteEmail,
                    subtitle: item.shopName || 'No Shop Info',
                    rewardn: item.rewardEarned,
                    event:item.event.eventName,
                    eventStart:item.event.dateStart,
                    eventEnd:item.event.dateEnd,
                    icon: item.status === 'Success' ?  <IconGreen />: <IconRed />,
                    price: `+${item.price}`,
                    status: 
                    item.status === 'Success' ? <CompleteBlog /> : 
                    item.status === 'Pending' ? <PendingBlog /> : 
                    item.status === 'Time Out' ? <TimeOutBlog /> : 
                    null,                    
                    cardbody:   
                     <div className='col-xl-4 col-xxl-6 col-lg-6'>
                    <div className='card'>
                      <div className='card-header border-0 pb-0'>
                        <h4 className='card-title'>Activities</h4>
                      </div>
                      <div className='card-body'>
                        <PerfectScrollbar
                          style={{ height: '100%' }}
                          id='DZ_W_TimeLine1'
                          className='widget-timeline dz-scroll style-1 height370 ps ps--active-y'
                        >
                          <ul className='timeline'>
                          {Object.entries(item.activity)
                            .filter(([key, value]) => value !== null) // Exclude null values
                            .map(([key, value], i) => (
                              <li key={i}>
                                <div className={`timeline-badge ${value ? 'success' : 'danger'}`} />
                                <div className="timeline-panel text">
                                  <p>{key.replace('activity', '')} {value ? 'Completed' : 'Not Completed'}</p>
                                </div>
                              </li>
                            ))}
                            {/* <li>
                              <div className='timeline-badge success'></div>
                              <Link
                                className='timeline-panel text-muted'
                              >
                                <span>15 minutes ago</span>
                                <h6 className='mb-0'>
                                  StumbleUpon is acquired by eBay.{' '}
                                </h6>
                              </Link>
                            </li>
                            <li>
                              <div className='timeline-badge warning'></div>
                              <Link
                                className='timeline-panel text-muted'
                              >
                                <span>20 minutes ago</span>
                                <h6 className='mb-0'>
                                  Mashable, a news website and blog, goes live.
                                </h6>
                              </Link>
                            </li>
                            <li>
                              <div className='timeline-badge dark'></div>
                              <Link
                                className='timeline-panel text-muted'
                              >
                                <span>20 minutes ago</span>
                                <h6 className='mb-0'>
                                  Mashable, a news website and blog, goes live.
                                </h6>
                              </Link>
                            </li> */}
                          </ul>
                        </PerfectScrollbar>
                      </div>
                    </div>
                  </div>
                }));

                setAccordionBlog(formattedData);
                setTotalPages(Math.ceil(data.totalCount / data.numItemsPerPage));
            })
            .catch((error) => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]); // Re-fetch when currentPage or searchQuery changes

    // Function to handle search input
const filteredData = accordionBlog
    ? accordionBlog.filter((data) =>
        data.title?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : []; // Use an empty array as a fallback if accordionBlog is undefined

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(0);
    };
    const checkDateType = (date) => {
    };
    const formatDateTime = (date) => {

      return moment(date).format("YYYY-MM-DD");
  };

  const handleBackToList = () => {
    navigate(`/${secretKey}/Users-list`); // Replace with the path to your list view
};
    return (
        <div className="col-xl-12">
            <Tab.Container defaultActiveKey="Monthly">
                <div className="card">
                    <div className="card-header d-block d-sm-flex border-0">
                        <div>
                            <h4 className="fs-20 text-black">Referral List</h4>
                            <input
                                type="text"
                                placeholder="Search by title"
                                value={searchTerm}
                                onChange={handleSearch}
                                className="search-input form-control"
                            />
                        </div>
                        <div className="card-action card-tabs mt-3 mt-sm-0">
                            {/* <Nav as="ul" className="nav nav-tabs" role="tablist">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="Monthly">Monthly</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="Weekly">Weekly</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="Today">Today</Nav.Link>
                                </Nav.Item>
                            </Nav> */}
                    <button className='btn btn-secondary ms-2' onClick={handleBackToList}>
                            Back to List
                        </button>
                        </div>
                    </div>

                    <Tab.Content className="card-body payment-bx tab-content p-0">
                        <Tab.Pane eventKey="Monthly">
                            <Accordion defaultActiveKey="0" className="accordion accordion-primary" id="accordion-one">
                            {/* <Accordion.Header as="div" className="d-flex align-items-center accordion__header rounded">

                                            <span className="px-2">Reward earned: <br/></span>
                                            <span className="px-2">Reward earned: <br/></span>
                                            <span className="px-2">Reward earned: <br/></span>
                                            <span className="font-w600 px-2"></span>
                                            <span className="font-w600 px-2">Start date: </span>
                                            <span className="font-w600 px-2">End date: </span>
                                            <span className="px-2">Reward earned: <br/></span>
                                            
                                        </Accordion.Header> */}
                                {filteredData.map((data, i) => (
                                    <Accordion.Item className="accordion__item border-bottom mb-0" key={i} eventKey={`${i}`}>
                                        <Accordion.Header as="div" className="d-flex align-items-center accordion__header rounded">
                                            <div className="profile-image me-4">
                                                <img src={data.image} alt="" width="63" className="rounded-circle" />
                                                {data.icon}
                                            </div>
                                            <div >
                                                <h6 className="fs-16 font-w700 mb-0">
                                                    <Link to={"#"}>{data.title}</Link>
                                                </h6>
                                            </div>
                                            <span className="px-2">Reward earned: {data.rewardn}<br/></span>
                                            <span className="font-w600 px-2">{data.event}</span>
                                            <span className="font-w600 px-2">Start date: {formatDateTime(data.eventStart.date)}</span>
                                            <span className="font-w600 px-2">End date: {formatDateTime(data.eventEnd.date)}</span>

                                            {data.status}
                                        </Accordion.Header>
                                        <Accordion.Collapse eventKey={`${i}`} className="accordion__body">
                                            {data.cardbody}
                                        </Accordion.Collapse>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                            {/* Pagination Controls */}
                            <div className="pagination mt-3">
                                <button
                                    disabled={currentPage === 0}
                                    onClick={() => setCurrentPage((prev) => prev - 1)}
                                    className="btn btn-primary me-2"
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage + 1} of {totalPages}</span>
                                <button
                                    disabled={currentPage === totalPages - 1}
                                    onClick={() => setCurrentPage((prev) => prev + 1)}
                                    className="btn btn-primary ms-2"
                                >
                                    Next
                                </button>
                            </div>
                        </Tab.Pane>
                        {/* Other panes omitted for brevity */}
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    );
};

export default Rewards;
