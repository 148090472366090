import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../../services/AuthService'; 
import {secretKey} from '../../../../config/env';
import { CardsService } from '../../../../services/CardsService';
import { Card, Col } from 'react-bootstrap';
import { EventService } from '../../../../services/EventService';
import { ParamaterService } from '../../../../services/ParameterService';

const EventDetails = () => {
    const { Id } = useParams();
    const [data, setData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState("Success");
    const [paramEvent, setParamEvent] = useState({ name: '', value: false });
    const [isConfirmed, setIsConfirmed] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (Id) {
            EventService.getById(Id)
                 .then((response) => {
                    const { data } = response.data;
                    if (data) {
                        setData(data[0]);                        
                        
                    }  else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);


    useEffect(() => {
      ParamaterService.getAllParams(2) // Call the API without pagination
          .then((response) => {
              const { data } = response.data;
  
              // Find the parameter with the name 'ExpireAllReferralCode'
              const parameter = data.items.find(item => item.name === 'ExpireAllReferralCode');
              if (parameter) {
                  // Store the name and value in a variable
                  const paramValue = parameter.value;
                  if (paramValue === "true"){
                    setIsConfirmed("Activating this event will expire all existing referal codes, Are you sure you want to continue");
                    
                  }
                  else{
                    setIsConfirmed("Are you sure you want to activate this event?");
                
                  }
                  // Use it as needed
                  setParamEvent({ name: parameter.name, value: paramValue });
                  
              } else {
                  console.warn('ExpireAllReferralCode parameter not found.');
              }
          })
          .catch((error) => {
              console.error('Error fetching data:', error);
          });
  }, []);
  


const handleActivate = () =>{
  const eventData = {
    id:Id,
    isActive:true
  }

  


  const confirm = window.confirm(isConfirmed);
        if(confirm){
        EventService.changeEventStatus(eventData)
        .then((response) => {
            swal("Good job!", "Event Activated successfully", "success")
            .then(() => {
                // Redirect to another page
                window.location.href = `/${secretKey}/events`; // Replace with your desired route
            });
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
          swal("Ooops!", errorMessage, "error");
        });
        }
}

const handleDesactivate = () =>{
  const eventData = {
    id:Id,
    isActive:false
  }
  const isConfirmed = window.confirm("Are you sure you want to desactivate this event?");
  if(isConfirmed){
  EventService.changeEventStatus(eventData)
  .then((response) => {
      swal("Good job!", "Event Desactivated successfully", "success")
      .then(() => {
          // Redirect to another page
          window.location.href = `/${secretKey}/events`; // Replace with your desired route
      });
  })
  .catch((error) => {
      const errorMessage = error.response?.data.message || 'An unexpected error occurred';
      swal("Ooops!", errorMessage, "error");
  });
  }

}

const handleBackToList = () => {
  navigate(`/${secretKey}/events`); // Replace with the path to your list view
};
    return (

        <Fragment>
        { data? (
            <Col xl='12'>
              <Card>
                  <Card.Header>
                  <Card.Title>Event Id : {data.id} </Card.Title>
                  {data.isActive ? (
                  <button className='btn btn-danger ms-2' onClick={handleDesactivate}>
                        Desactivate</button> 
                      ):(<button className='btn btn-success ms-2' onClick={handleActivate}>
                        Activate
                    </button>)}
                    <button className='btn btn-secondary ms-2' onClick={handleBackToList}>
                          Back to List
                      </button>
                  </Card.Header>
                  
                  <Card.Body>
                  <Card.Text>
                    <h4><b>Event Name:</b></h4> 
                    <h5>{data.eventName}</h5>
                    <hr/>

                    <h4><b>Event Acronym:</b></h4> 
                    <h5>{data.eventAcronym}</h5>
                    <hr/>
                    <h4><b>Reward Number:</b></h4> 
                    <h5>{data.rewardNumbers || 'N/A'}</h5>
                    <hr/>
                    <h4><b>Status:</b></h4>
                    <h5>
                        {data.isActive ? (
                            <span className='badge badge-success'>Active</span>
                        ) : (
                            <span className='badge badge-danger'>Inactive</span>
                        )}
                    </h5>
                    <hr/>

                    <h4><b>Start Date:</b></h4> 
                    <h5>{data.startDate?.date}</h5>
                    <hr/>

                    <h4><b>End Date:</b></h4> 
                    <h5>{data.endDate?.date}</h5>
                    <hr/>

                    <h4><b>Activity Types:</b></h4>
                    <h5>
                        {data.activityTypes && data.activityTypes.length > 0
                            ? data.activityTypes.join(', ')
                            : 'N/A'}
                    </h5>
                    <hr/>

                    <h4><b>Reward Amount:</b></h4> 
                    <h5>{data.rewardCondition[0]?.rewardAmount || 'N/A'}</h5>
                    <hr/>

       
{/* 
                    <h4><b>Reward Condition:</b></h4>
                    <h5>
                        {data.rewardCondition[0]?.requiredActivities
                            ? Object.entries(data.rewardCondition[0].requiredActivities)
                                .filter(([, amount]) => amount !== 0) // Filter out entries with value 0
                                .map(([activity, amount]) => `${activity}: ${amount}`)
                                .join(', ')
                            : 'N/A'}
                    </h5> */}
                  <h4><b>Reward Condition:</b></h4>
                  <h5>
                      {data.rewardCondition[0]?.requiredActivities
                          ? Object.entries(data.rewardCondition[0].requiredActivities)
                              .filter(([, amount]) => amount !== 0) // Filter out entries with value 0
                              .map(([activity, amount]) => 
                                (activity === 'Signup' || activity === 'CardCreation')                                       
                                ? `${activity}: ${amount === 1 ? 'Yes' : 'No'}`  // Show 'Yes' if amount is 1, otherwise 'No'
                                : `${activity}: ${amount}`  // Otherwise, show the amount
                              )
                              .join(', ')
                          : 'N/A'}
                  </h5>

                    <hr/>
                  </Card.Text>
                  </Card.Body>
                  <Card.Footer className=' d-sm-flex justify-content-between align-items-center'>
                  <Card.Text className=' text-dark d-inline'>
                      {/* Last updated 3 mins ago */}
                  </Card.Text>
               
            
                  </Card.Footer>
              </Card>
              </Col>
        ):(

          <div className="">no data</div>
        )
      }
      </Fragment>
    );
};

export default EventDetails;
