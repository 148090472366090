import axios from 'axios';
import {  API_URL } from '../config/env';


const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const UserService = {

    getAllUsers:(page) =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/user?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getAllNotifcation:(page)=>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/read/notification?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    
    getAllN:(page) =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/notification?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    
    getUserById:(id) =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/user/${id}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    
    getDepositMetric:(id) =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/user/${id}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    getQrCode:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/api/user/qr/google`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    createQrCode:(data) =>{
        const token = getToken();
        return axios.post(`${API_URL}/api/user/google`, data , {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    notify:(data) =>{
        const token = getToken();
        return axios.post(`${API_URL}/api/notify-client`, data , {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

};