import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from './GlobalFilter'; 
import './filtering.css';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router
import { checkAutoLogin } from '../../../services/AuthService'; 
import { CardsService } from '../../../services/CardsService';
import { COLUMNS } from './Columns';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; 
import swal from "sweetalert";
import { Oval } from "react-loader-spinner";

export const SubscriptionList = () => {
    const columns = useMemo(() => COLUMNS, []);
    const [data, setData] = useState([]); // State to store the fetched data
    const [currentPage, setCurrentPage] = useState(0); // Current page number (0-based index)
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [count, setCount] = useState([]); // State to store the fetched data
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);
    // Fetch the card data using the card service
    useEffect(() => {
        // You can modify the service method to accept page number and items per page
        CardsService.getSubList(currentPage+1) // API expects 1-based index, so add 1
        .then((response) => {
            const { data } = response.data;
            setData(data.items); // Set the data received from API
            setCount(data.length);
            const calculatedTotalPages = Math.ceil(data.totalCount / data.numItemsPerPage);
            setCurrentPage(data.currentPageNumber - 1); // API currentPageNumber is 1-based, adjust for 0-based
            setTotalPages(calculatedTotalPages);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
    }, [currentPage]); // Re-fetch data when currentPage changes

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: currentPage },
            manualPagination: true, // Tell react-table that pagination is handled manually
            pageCount: totalPages,  // Pass the total number of pages
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance;

    const { globalFilter, pageIndex } = state;

    // Pagination control functions
    const handleNextPage = () => {
        if (canNextPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (canPreviousPage) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleGotoPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    // const cron = () => {
    //     const isConfirmed = window.confirm("Are you sure you want to update the data?");
    //     const data= {};
    //     if (isConfirmed) {
    //         CardsService.chroneSub(data)
    //       .then(response => {
    //         swal("Good job!", "data updated successfully", "success")
    //       })
    //       .catch(error => {
    //         const errorMessage = error.response?.data.message || 'An unexpected error occurred';
    //         swal("Ooops!", errorMessage, "error");
    //     });
    //     }    };
    const cron = () => {
        const isConfirmed = window.confirm("Are you sure you want to update the data?");
        if (!isConfirmed) return;

        setLoading(true);
        const data = {};

        CardsService.chroneSub(data)
            .then(response => {
                swal("Good job!", "Data updated successfully", "success");
            })
            .catch(error => {
                const errorMessage = error.response?.data.message || "An unexpected error occurred";
                swal("Ooops!", errorMessage, "error");
            })
            .finally(() => {
                setLoading(false);
            });
    };
const exportToPDFCurrent = () => {
        const doc = new jsPDF();
        const tableColumn = columns.map(column => column.Header);
    
        // Helper function to safely access nested properties
        const getNestedValue = (obj, accessor) => {
            return accessor.split('.').reduce((acc, key) => acc && acc[key], obj);
        };
    
        const tableRows = data.map(row =>
            columns.map(column => {
                // Check if accessor is a function or a string for nested properties
                if (typeof column.accessor === 'function') {
                    return column.accessor(row);
                }
                return getNestedValue(row, column.accessor) || ''; // Use helper for nested access
            })
        );
    
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
        });
    
        doc.save('Cards_Subscribtions_per_page.pdf');
    };
    
        // Function to fetch all pages of data
        const fetchAllData = async () => {
            let allData = [];
            let page = 1;
    
            while (page <= totalPages) {
                try {
                    const response = await CardsService.getSubList(page) // Adjust service call for each page
                    const { items } = response.data.data; // Adjust based on response structure
                    allData = [...allData, ...items];
                    page++;
                } catch (error) {
                    console.error('Error fetching data:', error);
                    break;
                }
            }
            return allData;
        };
    
        // Function to export all data to PDF
        const exportToPDF = async () => {
            const allData = await fetchAllData(); // Fetch all data
            const doc = new jsPDF();
            const tableColumn = columns.map(column => column.Header); // Get the column headers
            const getNestedValue = (obj, accessor) => {
                return accessor.split('.').reduce((acc, key) => acc && acc[key], obj);
            };
        
            const tableRows = allData.map(row =>
                columns.map(column => {
                    // Check if accessor is a function or a string for nested properties
                    if (typeof column.accessor === 'function') {
                        return column.accessor(row);
                    }
                    return getNestedValue(row, column.accessor) || ''; // Use helper for nested access
                })
            );
            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
            });
    
            doc.save('all_Cards_Subscribtions.pdf');
        };
    return (
        <>
            <Fragment>
            { data ? (
            <div className="card">

                <div className="card-header">
                    <h4 className="card-title">Subscription list<br/><br/>
                    
                    <h5>Total Requests: {count}</h5>

                    </h4>
                    <button className="btn btn-primary" onClick={exportToPDFCurrent}>Export current page</button>

                    <button className="btn btn-primary" onClick={exportToPDF}>Export All Data</button>


                    {/* <button className="btn btn-warning" onClick={cron}>Cron sub</button> */}
                    <button onClick={cron} disabled={loading} className="btn update-btn btn-warning">
                        {loading ? (
                            <Oval
                                height={20}
                                width={20}
                                color="#fff"
                                secondaryColor="#ccc"
                                strokeWidth={5}
                                ariaLabel="loading"
                            />
                        ) : (
                            "CRON Subscriptions"
                        )}
                    </button>
                </div>
                
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                                {column.canFilter ? column.render('Filter') : null}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="">
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {totalPages}
                                </strong>{''}
                            </span>
                            <span className="table-index">
                                Go to page : {' '}
                                <input
                                    type="number"
                                    className="ms-2"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                        handleGotoPage(pageNumber);
                                    }}
                                />
                            </span>
                        </div>
                        <div className="text-center">
                            <div className="filter-pagination mt-3">
                                <button className="previous-button" onClick={() => handleGotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                                <button className="previous-button" onClick={handlePreviousPage} disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={handleNextPage} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className="next-button" onClick={() => handleGotoPage(totalPages - 1)} disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ):(<div></div>)}
			</Fragment>
        </>
    );
}

export default SubscriptionList;
