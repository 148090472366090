import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext"; 

const root = ReactDOM.createRoot(document.getElementById('root'));

if ("serviceWorker" in navigator ) {
  // if("PushManager" in window){
  navigator.serviceWorker.register("/service-worker.js").then((registration) => {

    if (registration.installing) {
     // console.log("Installation du service worker en cours");
    } else if (registration.waiting) {
      //console.log("Service worker installé");
    } else if (registration.active) {
      // console.log("Service worker actif");
    }

    registration.update();
   // console.log('Scope du service worker :', registration.scope);

  }).catch((error) => {
    console.error("Service Worker registration failed:", error);
  });
} else {
  console.warn("Push notifications are not supported in this browser.");
}
root.render(
  // <React.StrictMode>
    <Provider store = {store}>
      <BrowserRouter basename='/'>
        <ThemeContext>
          <App />
        </ThemeContext>
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);
reportWebVitals();