import React, { Fragment, useEffect, useState } from "react";

import swal from "sweetalert";
import PageTItle from "../../../layouts/PageTitle";
import { VccService } from "../../../../services/VccService";
import { Link, useParams, useNavigate } from "react-router-dom";
import { secretKey } from "../../../../config/env";

const AddCard = () => {
  const { Id } = useParams();
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isConfirmed = window.confirm(
      "Are you sure you want to add this card"
    );

    if (isConfirmed) {
      VccService.AddCard(Id, formData)
        .then((response) => {
          swal("Good job!", "Card Added successfully", "success").then(navigate(`/${secretKey}/cardrequests`));
        })
        
        .catch((error) => {
          console.log(error);
          const errorMessage =
          error.response?.data.message || "An unexpected error occurred";
          console.error("Error adding card:", errorMessage);
          swal("Ooops!", errorMessage, "error");
        });
    }
  };
const handleBackToList = () => {
  navigate(`/${secretKey}/pending-card-requests`); // Replace with the path to your list view
};


  return (
    <Fragment>
      {/* <PageTItle activeMenu="Element" motherMenu="Form" pageContent="Element" /> */}
              

      <div className="row"></div>

      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Add VCC Card</h4>
            <button className='btn btn-secondary ms-2' onClick={handleBackToList}>
                Back to List
            </button>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleSubmit}>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Card number</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Card number"
                      id="cardNumber"
                      name="cardNumber"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Holder Name</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Holder Name"
                      id="holderName"
                      name="holderName"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Card CVV</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="cvv"
                      id="cvv"
                      name="cvv"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Expiry Date</label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      className="form-control"
                      id="expiryDate"
                      name="expiryDate"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {/* <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Balance</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Balance"
                      id="balance"
                      name="balance"
                      onChange={handleInputChange}
                    />
                  </div>
                </div> */}
                <div className="mb-3 row">
                  <div className="col-sm-10">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddCard;
