
import { detectBrowser } from "./detectBrowser";
import {  API_URL } from '../config/env';
import { getUserFromLocalStorage } from "../store/actions/AuthActions";

const getToken = () => {
  const tokenHeader = localStorage.getItem('Header');
  const tokenPayload = localStorage.getItem('payload');
  const tokenSignature = localStorage.getItem('sig');

  return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};


export const subscribeToPush = async (email) => {
console.log("pushnotif",email)
console.log("navigator",navigator);

console.log("navigator.serviceWorker",navigator.serviceWorker);

  console.log("navigator.serviceWorker.ready",navigator.serviceWorker.ready);
  console.log("navigator.serviceWorker.controller",navigator.serviceWorker.controller
  );
    // try {
      const registration = await navigator.serviceWorker.ready;
     console.log("1",registration)
      
      // Request permission from the user
      const permission = await Notification.requestPermission();  
      console.log("2",permission) 
      if (permission !== "granted") {
        throw new Error("Permission not granted for notifications");
      }
      const token = getToken();
      // Subscribe to PushManager
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: 'BP30D-lWOmpfYpGvdoWHtyUYrbLex3wl8l_xQ8yE-ZLSOzj30ON4pSv765EyYx5wRq8m0w7YXSLrJkSmgz1VfQ0' // Replace with your public VAPID key
      });
      console.log("aa",subscription)
      // Build the payload
      const { endpoint, keys } = subscription.toJSON();
      const payload = {
        endpoint,
        keys,
        browser: detectBrowser(),
        clientIdentifier: email, 
      };
      // Send the payload to your API



      const response = await fetch(`${API_URL}/api/push/subscribe`, {
        method: "POST",
        headers: { 
          Authorization: `Bearer ${token}` ,
          "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Failed to send subscription to API");
      }
      console.log("api");
    // } 
    // catch (error) {
    //   console.error("Error during push subscription:", error);
    // }
  };