import {secretKey} from '../../../config/env';


export const MenuList = [
    //Dashboard
    // {
    //     title: 'Dashboard',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="flaticon-381-networking"></i>,
    //     content: [
    //         {
    //             title: 'Dashboard',
    //             to: '/dashboard',					
    //         },
    //         // {
    //         //     title: 'Dashboard Dark',
    //         //     to: 'dashboard-dark',
    //         // },            
    //         {
    //             title: 'My Wallet',
    //             to: '/my-wallet',
    //         },
    //         {
    //             title: 'Invoices',
    //             to: '/invoices',
    //         },
    //         {
    //             title: 'Cards Center',
    //             to: '/cards-center',                
    //         },
    //         {
    //             title: 'Transactions',
    //             to: '/transactions',
                
    //         },
    //         {
    //             title: 'Transactions Details',
    //             to: '/transactions-details',                
    //         },			
	// 		{
    //             title: 'Task',
    //             to: '/task',                
    //         },    
    //     ],
    // },
    {
        title: 'Dashboard Overview',	
        iconStyle: <i className="flaticon-381-networking"></i>,
        to:`/${secretKey}/dashboard`
            
        
    },
    {
        title: 'User Management',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-user"></i>,
        content: [
            {
                title: 'Users list',
                to: `/${secretKey}/Users-list`
            }
        ],
    },

    {
        title: 'Card Management',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="fa fa-credit-card"></i>,
        content: [
            {
                title: 'Pending Requests',
                to: `/${secretKey}/pending-card-requests`,					
            },
            {
                title: 'Approved Requests',
                to: `/${secretKey}/cardrequests`,					
            },      
            {
                title: 'Card Settings',
                to: `/${secretKey}/card-settings`,					
            },  
            {
                title: 'Active Cards',
                to: `/${secretKey}/Cards`,					
            },  
            {
                title: 'Top-up Requests',
                to: `/${secretKey}/pending-topup-requests`,					
            },    
            {
                title: 'Top-up History',
                to: `/${secretKey}/top-up-request`,					
            },  
            {
                title: 'Subscription List',
                to: `/${secretKey}/subscription-list`,					
            },          
        ],
    },
    {
        title: 'VCC Card',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="fa fa-credit-card"></i>,
        content: [
            {
                title: 'Pending Requests',
                to: `/${secretKey}/pending-card-requests`,					
            },
            {
                title: 'Approved Requests',
                to: `/${secretKey}/cardrequests`,					
            },      
            {
                title: 'Card Settings',
                to: `/${secretKey}/card-settings`,					
            },  
            {
                title: 'VCC Cards',
                to: `/${secretKey}/vcc-list`,					
            },  
            {
                title: 'Add Card Manually',
                to: `/${secretKey}/add-card-manual`,					
            }, 
        
        ],
    },
    // {
    //     title: 'Cards',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="fa fa-credit-card"></i>,
    //     content: [
    //         {
    //             title: 'Cards list',
    //             to: '/Cards',					
    //         },    
    //         {
    //             title: 'subscription',
    //             to: '/subscription',					
    //         },        
    //     ],
    // },
    //Apps
    {
        title: 'Wallet',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="fa fa-wallet"></i>,
        content: [
            {
                title: 'Deposit Request',
                to: `/${secretKey}/client-deposit-request`
            },
            {
                title: 'Client Wallets',
                to: `/${secretKey}/client-wallet`
            },
            // {
            //     title: 'Edit Profile',
            //     to: 'edit-profile'
            // },
            {
                title: 'Admin Wallets',
                to: `/${secretKey}/admin-wallet`
            },
            {
                title: 'Master Wallet Topup',
                to: `/${secretKey}/topup-wallet`
            },
            // {
            //     title: ' Adjust Client Wallet',
            //     to: `/${secretKey}/admin-wallet`
            // }
        ],
    },
    {
        title: 'Referal System',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-settings"></i>,
        content: [
            // {
            //     title: 'Referal list',
            //     to: `/${secretKey}/rewards`,					
            // }, 
            {
                title: 'Events',
                to: `/${secretKey}/events`,					
            }
        ],
    },
    {
        title: 'Reports',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-settings"></i>,
        content: [
            {
                title: 'User Reports',
                to: `/${secretKey}/user-report`,					
            }, 
            {
                title: 'Card Top-up Reports',
                to: `/${secretKey}/card-topup-report`,					
            }, 
            {
                title: 'Wallet Deposit Reports',
                to: `/${secretKey}/wallet-deposit`
            },
            {
                title: 'Master Wallet Reports',
                to: `/${secretKey}/master-wallet`,					
            }, 
            {
                title: 'Financial Reports',
                to: `/${secretKey}/cardrequests`,					
            }, 
            {
                title: 'Export Transaction List',
                to: `/${secretKey}/transaction-report`,					
            }, 
        ],
    },
    {
        title: 'Settings',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-settings-2"></i>,
        content: [
            {
                title: 'Notifications',
                to: `/${secretKey}/notifications`
            },
            {
                title: 'Recent Alerts',
                to: `/${secretKey}/Params`
            },
            {
                title: 'Security',
                to: `/${secretKey}/Params`
            },
            {
                title: 'Parameter',
                to: `/${secretKey}/Params`
            }
        ],
    },
    //Charts
    // {
    //     title: 'Charts',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-controls-3"></i>,
    //     content: [
            
    //         {
    //             title: 'RechartJs',
    //             to: '/chart-rechart',					
    //         },
    //         {
    //             title: 'Chartjs',
    //             to: '/chart-chartjs',					
    //         },
    //         {
    //             title: 'Sparkline',
    //             to: '/chart-sparkline',					
    //         },
    //         {
    //             title: 'Apexchart',
    //             to: '/chart-apexchart',					
    //         },
    //     ]
    // },
    // //Boosttrap
    // {
    //     title: 'Bootstrap',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-internet"></i>,	
    //     content: [
    //         {
    //             title: 'Accordion',
    //             to: '/ui-accordion',					
    //         },
    //         {
    //             title: 'Alert',
    //             to: '/ui-alert',					
    //         },
    //         {
    //             title: 'Badge',
    //             to: '/ui-badge',					
    //         },
    //         {
    //             title: 'Button',
    //             to: '/ui-button',					
    //         },
    //         {
    //             title: 'Modal',
    //             to: '/ui-modal',					
    //         },
    //         {
    //             title: 'Button Group',
    //             to: '/ui-button-group',					
    //         },
    //         {
    //             title: 'List Group',
    //             to: '/ui-list-group',					
    //         },
    //         {
    //             title: 'Cards',
    //             to: '/ui-card',					
    //         },
    //         {
    //             title: 'Carousel',
    //             to: '/ui-carousel',					
    //         },
    //         {
    //             title: 'Dropdown',
    //             to: '/ui-dropdown',					
    //         },
    //         {
    //             title: 'Popover',
    //             to: '/ui-popover',					
    //         },
    //         {
    //             title: 'Progressbar',
    //             to: '/ui-progressbar',					
    //         },
    //         {
    //             title: 'Tab',
    //             to: '/ui-tab',					
    //         },
    //         {
    //             title: 'Typography',
    //             to: '/ui-typography',					
    //         },
    //         {
    //             title: 'Pagination',
    //             to: '/ui-pagination',					
    //         },
    //         {
    //             title: 'Grid',
    //             to: '/ui-grid',					
    //         },
    //     ]
    // },
    // //plugins
    // {
    //     title:'Plugins',
    //     classsChange: 'mm-collapse',
    //     iconStyle : <i className="flaticon-381-heart"></i>,
    //     content : [
    //         {
    //             title:'Select 2',
    //             to: '/uc-select2',
    //         },
    //         // {
    //         //     title:'Noui Slider',
    //         //     to: 'uc-noui-slider',
    //         // },
    //         {
    //             title:'Sweet Alert',
    //             to: '/uc-sweetalert',
    //         },
    //         {
    //             title:'Toastr',
    //             to: '/uc-toastr',
    //         },
    //         {
    //             title:'Jqv Map',
    //             to: '/map-jqvmap',
    //         },
    //         {
    //             title:'Light Gallery',
    //             to: '/uc-lightgallery',
    //         },
    //     ]
    // },
    // //Widget
    // {   
    //     title:'Widget',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-settings-2"></i>,
    //     to: '/widget-basic',
    // },
    // //Forms
    // {
    //     title:'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-notepad"></i>,
    //     content : [
    //         {
    //             title:'Form Elements',
    //             to: '/form-element',
    //         },
    //         {
    //             title:'Wizard',
    //             to: '/form-wizard',
    //         },
    //         {
    //             title:'CkEditor',
    //             to: '/form-ckeditor',
    //         },
    //         {
    //             title:'Pickers',
    //             to: '/form-pickers',
    //         },
    //         {
    //             title:'Form Validate',
    //             to: '/form-validation',
    //         },

    //     ]
    // },
    // //Table
    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-network"></i>,
    //     content : [
    //         {
    //             title:'Table Filtering',
    //             to: '/table-filtering',
    //         },
    //         {
    //             title:'Table Sorting',
    //             to: '/table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: '/table-bootstrap-basic',
    //         },
           

    //     ]
    // },
    // //Pages
    // {
    //     title:'Pages',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-layer-1"></i>,
    //     content : [
    //         {
    //             title:'Error',
    //             hasMenu : true,
    //             content : [
    //                 {
    //                     title: 'Error 400',
    //                     to : '/page-error-400',
    //                 },
    //                 {
    //                     title: 'Error 403',
    //                     to : '/page-error-403',
    //                 },
    //                 {
    //                     title: 'Error 404',
    //                     to : '/page-error-404',
    //                 },
    //                 {
    //                     title: 'Error 500',
    //                     to : '/page-error-500',
    //                 },
    //                 {
    //                     title: 'Error 503',
    //                     to : '/page-error-503',
    //                 },
    //             ],
    //         },
    //         {
    //             title:'Lock Screen',
    //             to: '/page-lock-screen',
    //         },

    //     ]
    // },
    
]