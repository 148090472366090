import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { UserService } from "../../../services/UserService";

const QrCodeValidationModal = ({ show, handleClose }) => {
  const [password, setPassword] = useState("");
  const [auth_code, setAuthCode] = useState("");
  const [validationResult, setValidationResult] = useState(null);

  const handleValidation = () => {
    const validationData = { password, auth_code };

    UserService.createQrCode(validationData)
      .then((response) => {
        setValidationResult("Validation successful!");
      })
      .catch((error) => {
        setValidationResult("Validation failed. Please try again.");
        console.error("Validation error:", error);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Validate QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formCode" className="mt-3">
            <Form.Label>6-Digit Code</Form.Label>
            <Form.Control
              type="text"
              maxLength="6"
              placeholder="Enter 6-digit code"
              value={auth_code}
              onChange={(e) => setAuthCode(e.target.value)}
            />
          </Form.Group>
        </Form>
        {validationResult && (
          <p className={`mt-3 ${validationResult.includes("success") ? "text-success" : "text-danger"}`}>
            {validationResult}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleValidation}
          disabled={!password || auth_code.length !== 6} // Disable if inputs are invalid
        >
          Validate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QrCodeValidationModal;
