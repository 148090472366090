
import {
    formatError,
    login,
    // runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,send2FACode
} from '../../services/AuthService';

import {secretKey} from '../../config/env';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { subscribeToPush } from '../../services/subscribeToPush';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';



export function signupAction(email, password, navigate) {
	
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            // runLogoutTimer(
            //     dispatch,
            //     response.data.expiresIn * 1000,
            //     //history,
            // );
            dispatch(confirmedSignupAction(response.data));
            navigate(`/${secretKey}/dashboard`);
			//history.push('/dashboard');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function Logout(navigate) {

	localStorage.removeItem('Header');
    localStorage.removeItem('payload');
	localStorage.removeItem('sig');
	//history.push('/login');
    navigate(`/${secretKey}/login`); 

	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate,setShow2AFModal) {
    return (dispatch) => {
        send2FACode(email, password)

            .then((response) => {
                if(response.data?.data?.type==='simple'){
                    saveTokenInLocalStorage(response.data);
                    dispatch(loginConfirmedAction(response.data)); 
                    navigate(`/${secretKey}/dashboard`);
                    // subscribeToPush(email);
                }
                else {
                setShow2AFModal(true); // Show the 2FA modal to the user
                }
            })
            .catch((error) => {console.log(error)
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function verify2FAAction(email, password, code, navigate) {
    return (dispatch) => {
        login(email, password, code)
            .then((response) => { 
                saveTokenInLocalStorage(response.data);
                dispatch(loginConfirmedAction(response.data)); 
                navigate(`/${secretKey}/dashboard`);
                // subscribeToPush(email);
                // window.location.reload(); // As a last resort, reload the page
            })
            .catch((error) => {
                // Capture the error message
                let errorMessage = "An error occurred during 2FA verification.";
                
                // If the error contains a response, extract the message
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                
                // Dispatch the error to the Redux store to be displayed
                dispatch(loginFailedAction(errorMessage));
                
                // Show SweetAlert with the error message
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: errorMessage,
                    confirmButtonText: 'Close'
                });

                // Optionally, log the error to the console for debugging
                console.error(errorMessage);
            });
    };
}
export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export function getUserFromLocalStorage() {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    if (tokenHeader && tokenPayload && tokenSignature) {
        // Combine the header, payload, and signature to form the complete JWT token

        // Now, decode the payload
        const base64Url = tokenPayload;  // This is the middle part of the token (the payload)
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');  // Fix URL-safe base64 encoding

        try {
            const decodedPayload = atob(base64);  // Decode the base64 string
            const userData = JSON.parse(decodedPayload);  // Parse the decoded payload into JSON
            return userData;  // Return the decoded user data
        } catch (error) {
            console.error('Error decoding JWT payload:', error);
            return null;
        }
    } else {
        console.error('JWT token parts not found in localStorage.');
        return null;
    }
}

  