import axios from 'axios';
import {  API_URL } from '../config/env';


const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const ReportService = {

    getUsersReport:() =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/reports/user`,{"selectUser":""}, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    getUsersReportByUser:(selectedUser) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/reports/user`,{"selectUser":selectedUser}, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },



    getCardTopupReport:() =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/reports/card/topup`,{}, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getCardTopupReportByEmail:(data) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/reports/card/topup`, data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getDepositWalletReport:(data) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/reports/wallet/deposit`,data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getMasterWalletReport:(data) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/reports/master/wallet`,data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    getTransactionReport:(data,id) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/reports/transaction/card/${id}`,data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
};