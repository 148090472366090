import axios from 'axios';
import {  API_URL } from '../config/env';


const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const CardsService = {

    getAll: (page) => {
        const token = getToken();
        return axios.get(`${API_URL}/api/all-holder-card`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
}