import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter'; 
import './filtering.css';
import { Fragment } from 'react';
import { ParamaterService } from '../../../services/ParameterService';
import Add from './Add';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../services/AuthService'; 
export const Parameter = () => {
    const columns = useMemo(() => COLUMNS, []);
    const [data, setData] = useState([]); // State to store the fetched data
    const [currentPage, setCurrentPage] = useState(0); // Current page number (0-based index)
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [isModalOpen, setModalOpen] = useState(false);
    const [refreshList, setRefreshList] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);


    useEffect(() => {
        ParamaterService.getAllParams(currentPage+1) // API expects 1-based index, so add 1
            .then((response) => {
                const { data, currentPageNumber, numItemsPerPage, totalCount } = response.data;
                setData(data.items); // Set the data received from API
                console.log(data.items);
                // Calculate total pages
                const calculatedTotalPages = Math.ceil(data.totalCount / data.numItemsPerPage);
                setCurrentPage(data.currentPageNumber - 1); // API currentPageNumber is 1-based, adjust for 0-based
                setTotalPages(calculatedTotalPages);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [currentPage,refreshList]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: currentPage },
            manualPagination: true, // Tell react-table that pagination is handled manually
            pageCount: totalPages,  // Pass the total number of pages
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance;

    const { globalFilter, pageIndex } = state;

    // Pagination control functions
    const handleNextPage = () => {
        if (canNextPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (canPreviousPage) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleGotoPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleAdd = () => {
        setModalOpen(true); // Open the modal
    };

    const handleRefresh = () => {
        setRefreshList((prev) => !prev); // Toggle refreshList state
    };

    return (
        <>
         
            { data? (
            <Fragment>
			<div className="form-head mb-4">
				<h2 className="text-black font-w600 mb-0">Parameters List</h2>
			</div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">		
                        <button type="button" className="btn btn-rounded btn-primary" onClick={handleAdd}>
                            <span className="btn-icon-left text-primary"><i className="fa fa-plus" aria-hidden="true"></i>
					        </span>New Param
                            </button>
                            
                        {isModalOpen && (
                            <Add
                                isOpen={isModalOpen} 
                                onClose={() => {
                                    setModalOpen(false);
                                    handleRefresh(); // Refresh list after modal is closed
                                }}
                            />
                        )}
                    </h4>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                                {column.canFilter ? column.render('Filter') : null}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="">
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {totalPages}
                                </strong>{''}
                            </span>
                            <span className="table-index">
                                Go to page : {' '}
                                <input
                                    type="number"
                                    className="ms-2"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                        handleGotoPage(pageNumber);
                                    }}
                                />
                            </span>
                        </div>
                        <div className="text-center">
                            <div className="filter-pagination mt-3">
                                <button className="previous-button" onClick={() => handleGotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                                <button className="previous-button" onClick={handlePreviousPage} disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={handleNextPage} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className="next-button" onClick={() => handleGotoPage(totalPages - 1)} disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>			
            </Fragment>

            ):(<div>no data</div>)
        }
        </>
    );
}

export default Parameter;
