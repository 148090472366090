import React, { Fragment, useEffect, useReducer, useState } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

//** Import Image */
//** Import Image */
import profile01 from "../../../../images/profile/1.jpg";
import profile02 from "../../../../images/profile/2.jpg";
import profile03 from "../../../../images/profile/3.jpg";
import profile04 from "../../../../images/profile/4.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";
import { getUserFromLocalStorage } from "../../../../store/actions/AuthActions";
import QrCode from "../../QrCode/QrCode";
import QrCodeWithValidation from "../../QrCode/QrCodeWithValidation";

const galleryBlog = [
	{image: profile03}, {image: profile04},
	{image: profile02}, {image: profile04},
	{image: profile03}, {image: profile02},
];
const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}

const  AppProfile = () => {
	const [user, setUser] = useState(null);

	useEffect(() => {
	  const userData = getUserFromLocalStorage();
	  setUser(userData); // Set the user info in the state
	}, []);
	const onInit = () => {
		//console.log('lightGallery has been initialized');
	};
  	const options = {
     	settings: {
			overlayColor: "#000000",
     	},
 	};
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<Fragment>
		  {/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
	
		  <div className="row">
			<div className="col-lg-12">
			  <div className="profile card card-body px-3 pt-3 pb-0">
				<div className="profile-head">
				  <div className="photo-content ">
				  </div>
				  <div className="profile-info">
					<div className="profile-photo">
					</div>
					<div className="profile-details">
					  <div className="profile-email px-2 pt-2">
						<h4 className="text-muted mb-0">{user &&  user.email}</h4>
						<p>Email</p>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		  <div className="row">	
			<div className="col-xl-12">
			  <div className="card">
				<div className="card-body">
				  <div className="profile-tab">
					<div className="custom-tab-1">
						<Tab.Container defaultActiveKey='Posts'>					
							<Nav as='ul' className="nav nav-tabs">

								<Nav.Item as='li' className="nav-item">
									<Nav.Link to="#profile-settings" eventKey='Setting'>Settings</Nav.Link>
								</Nav.Item>
								<Nav.Item as='li' className="nav-item">
									<Nav.Link to="#my-posts" eventKey='Posts'>Advanced Security Settings</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane id="my-posts"  eventKey='Posts'>
									<div className="my-post-content pt-3">
									{user?.loginType==="google" ? (
										<div>
										google authenticator active
										</div>
							
										):(
											<div>
										<QrCode/>
										<QrCodeWithValidation/>
										</div>

										)}
									</div>
								</Tab.Pane>
								<Tab.Pane id="about-me" eventKey='About'>
									<div className="profile-about-me">
										<div className="pt-4 border-bottom-1 pb-3">
											<h4 className="text-primary">About Me</h4>
											<p className="mb-2">
												A wonderful serenity has taken possession of my
												entire soul, like these sweet mornings of spring
												which I enjoy with my whole heart. I am alone, and
												feel the charm of existence was created for the
												bliss of souls like mine.I am so happy, my dear
												friend, so absorbed in the exquisite sense of mere
												tranquil existence, that I neglect my talents.
											</p>
											<p>
												A collection of textile samples lay spread out on
												the table - Samsa was a travelling salesman - and
												above it there hung a picture that he had recently
												cut out of an illustrated magazine and housed in a
												nice, gilded frame.
											</p>
										</div>
									</div>
									<div className="profile-skills mb-5">
										<h4 className="text-primary mb-2">Skills</h4>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1"> Admin</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1" > Dashboard</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Photoshop</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Bootstrap</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Responsive</Link>
										<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Crypto</Link>
									</div>
									<div className="profile-lang  mb-5">
										<h4 className="text-primary mb-2">Language</h4>
										<Link to="/app-profile" className="text-muted pe-3 f-s-16">
											<i className="flag-icon flag-icon-us" />English
										</Link>
										<Link to="/app-profile" className="text-muted pe-3 f-s-16">
											<i className="flag-icon flag-icon-fr" />French
										</Link>
										<Link to="/app-profile" className="text-muted pe-3 f-s-16">
											<i className="flag-icon flag-icon-bd" />Bangla
										</Link>
									</div>
									<div className="profile-personal-info">
										<h4 className="text-primary mb-4">
											Personal Information
										</h4>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500"> Name<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>Mitchell C.Shay</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>example@examplel.com</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">  Availability<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>Full Time (Free Lancer)</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Age<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>27</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">  Location<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>Rosemont Avenue Melbourne, Florida</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Year Experience<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>07 Year Experiences</span>
											</div>
										</div>
									</div>
								</Tab.Pane>
								<Tab.Pane id="profile-settings" eventKey='Setting'>
									<div className="pt-3">
										<div className="settings-form">
											<h4 className="text-primary">Account Setting</h4>
											<form onSubmit={(e) => e.preventDefault()}>
												<div className="row">
													<div className="form-group mb-3 col-md-6">
														<label className="form-label" >Email</label>
														<input type="email" placeholder="Email" className="form-control"/>
													</div>
													<div className="form-group mb-3 col-md-6">
														<label className="form-label">Password</label>
														<input type="password" placeholder="Password" className="form-control"/>
													</div>
												</div>
												<div className="form-group mb-3">
													<label className="form-label">Address</label>
													<input type="text" placeholder="1234 Main St" className="form-control"/>
												</div>
												<div className="form-group mb-3">
													<label className="form-label">Address 2</label>
													<input type="text" placeholder="Apartment, studio, or floor" className="form-control"/>
												</div>
												<div className="row">
													<div className="form-group mb-3 col-md-6">
														<label className="form-label" >City</label>
														<input type="text" className="form-control" />
													</div>
													<div className="form-group mb-3 col-md-4">
														<label className="form-label">State</label>
														<select
														className="form-control"
														id="inputState"
														defaultValue="option-1"
														>
														<option value="option-1">Choose...</option>
														<option value="option-2">Option 1</option>
														<option value="option-3">Option 2</option>
														<option value="option-4">Option 3</option>
														</select>
													</div>
													<div className="form-group mb-3 col-md-2">
														<label className="form-label">Zip</label>
														<input type="text" className="form-control" />
													</div>
												</div>
												<div className="form-group mb-3">
													<div className="form-check custom-checkbox">
														<input
														type="checkbox"
														className="form-check-input"
														id="gridCheck"
														/>
														<label
														className="form-check-label"
														htmlFor="gridCheck"
														>
														Check me out
														</label>
													</div>
												</div>
												<button className="btn btn-primary" type="submit">Sign in</button>
											</form>
										</div>
									</div>
								</Tab.Pane>
							</Tab.Content>	
						</Tab.Container>		
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		  {/* send Modal */}
			  <Modal className="modal fade" show={state.sendMessage} onHide={()=>dispatch({type:'sendMessage'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Send Message</h5>
						<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'sendMessage'})}>
							
						</Button>
					</div>
					<div className="modal-body">
						<form className="comment-form" onSubmit={(e) => { e.preventDefault(); dispatch({type:'sendMessage'}); }}>
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="author" className="text-black font-w600">  Name <span className="required">*</span> </label>
										<input type="text" className="form-control" defaultValue="Author" name="Author" placeholder="Author" />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="email" className="text-black font-w600"> Email <span className="required">*</span></label>
										<input type="text" className="form-control" defaultValue="Email" placeholder="Email" name="Email"/>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group mb-3">
										<label htmlFor="comment" className="text-black font-w600">Comment</label>
										<textarea rows={4} className="form-control" name="comment" placeholder="Comment" defaultValue={""}/>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group mb-3">
										<input type="submit" value="Post Comment" className="submit btn btn-primary" name="submit"/>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		  {/* Post Modal */}
			  <Modal show={state.post} className="modal fade" id="postModal" onHide={() => dispatch({type:'postModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Post</h5>
						<Button variant=""  type="button" className="close" data-dismiss="modal" onClick={() => dispatch({type:'postModal'})} >
							<span>×</span>
						</Button>
						
					</div>
					<div className="modal-body">
						<textarea name="textarea" id="textarea" cols={30} rows={5} className="form-control mb-2 bg-transparent" placeholder="Please type what you want...." defaultValue={""}/>
						<Link className="btn btn-primary btn-rounded mt-1" to="/app-profile">Post</Link>
					</div>
				</div>
			</Modal>
			 {/* Link Modal */}
			  <Modal show={state.link}  className="modal fade post-input" id="linkModal" onHide={() => dispatch({type:'linkModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Social Links</h5>
						<button type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'linkModal'})}>
						</button>
					</div>
					<div className="modal-body">
						<Link className="btn-social me-1 facebook" to="/app-profile"><i className="fab fa-facebook-f" /></Link>
						<Link className="btn-social me-1 google-plus" to="/app-profile"> <i className="fab fa-google-plus" /></Link>
						<Link className="btn-social me-1 linkedin" to="/app-profile"><i className="fab fa-linkedin" /></Link>
						<Link className="btn-social me-1 instagram" to="/app-profile"> <i className="fab fa-instagram" /></Link>
						<Link className="btn-social me-1 twitter" to="/app-profile"><i className="fab fa-twitter" /></Link>
						<Link className="btn-social me-1 youtube" to="/app-profile"><i className="fab fa-youtube" /></Link>
						<Link className="btn-social whatsapp" to="/app-profile"><i className="fab fa-whatsapp" /></Link>
					</div>
				</div>
			</Modal>
			 {/* Camera Modal */}
			  <Modal show={state.camera}  className="modal fade" id="cameraModal" onHide={() => dispatch({type:'cameraModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Upload images</h5>
						<button type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'cameraModal'})}>
						</button>
					</div>
					<div className="modal-body">
						{/* <div className="input-group mb-3">
								<span className="input-group-text">Upload</span>
							<div className="form-file">
								<input type="file" className="form-file-input"/>
							</div>
						</div> */}
						<div className="input-group custom_file_input mb-3">
							<span className="input-group-text">Upload</span>
							<div className="form-file">
								<input type="file" className="form-file-input form-control" />
							</div>
						</div>
					</div>
				</div>
			</Modal>
			 {/* Reply Modal */}
			  <Modal   show={state.reply}  className="modal fade" id="replyModal" onHide={()=>dispatch({type:'replyModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Post Reply</h5>
						<button type="button" className="btn-close"  onClick={() => dispatch({type:'replyModal'})}></button>
					</div>
					<div className="modal-body">
						<form>
							<textarea className="form-control" rows="4">Message</textarea>
						</form>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-danger light"  onClick={() => dispatch({type:'replyModal'})}>Close</button>
						<button type="button" className="btn btn-primary">Reply</button>
					</div>
				</div>
			</Modal>
		</Fragment>
	  );
};

export default AppProfile;
