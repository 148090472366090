import React, { Fragment, useEffect, useState } from "react";

import swal from "sweetalert";
import PageTItle from "../../../layouts/PageTitle";
import { VccService } from "../../../../services/VccService";
import { Link, useParams,useNavigate } from 'react-router-dom';
import { secretKey } from "../../../../config/env";

const AddCardManual = () => {
    const [formData, setFormData] = useState({});
    const [emailList, setEmailList] = useState([]); // To store emails from the API
    const navigate = useNavigate();

    useEffect(() => {
        VccService.getUsersWithPaycard()
          .then((response) => {
            setEmailList(response.data.data) //.ing response.data contains the array of emails
            console.log(response.data.data);
          })
          .catch((error) => {
            console.error("Error fetching emails:", error);
            swal("Oops!", "Failed to fetch emails", "error");
          });
      }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

        const handleSubmit = (event) => {
            event.preventDefault();

            const isConfirmed = window.confirm(
              "Are you sure you want to add this card"
            );

            if (isConfirmed) {
                VccService.AddCardManually(formData)
                .then((response) => {
                    console.log("API Response:", response); // Debugging
                    swal("Good job!", "Card Added successfully", "success").then(navigate(`/${secretKey}/vcc-list`));
                  })
                   
                  .catch((error) => {
                    const errorMessage = error.response?.data.message || "An unexpected error occurred";
                    console.error("Error adding card:", errorMessage); // Debugging
                    swal("Oops!", errorMessage, "error");
                  });
              }
    };


  return (
    <Fragment>

      <div className="row"></div>

      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Add VCC Card</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
               <form onSubmit={handleSubmit}>
               <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-9">
                  <select
                        className="form-control"
                        id="email"
                        name="email"
                        onChange={(e) => {
                            const selectedId = e.target.value;
                            setFormData({ ...formData, email: selectedId });
                        }}
                        value={formData.email || ""}
                        >
                        <option value="">Select Email</option>
                        {emailList.map((item) => (
                            <option key={item.id} value={item.email}>
                            {item.email}
                            </option>
                        ))}
                        </select>

                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Card number</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Card number"
                      id="cardNumber"
                      name="cardNumber"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Holder Name</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Holder Name"
                      id="holderName"
                      name="holderName"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Card CVV</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="cvv"
                      id="cvv"
                      name="cvv"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Expiry Date</label>
                  <div className="col-sm-9">
                    <input
                        type="date"
                        className="form-control"
                        id="expiryDate"
                        name="expiryDate"
                        onChange={handleInputChange}
                />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-sm-10">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddCardManual;
