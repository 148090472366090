import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../../services/AuthService'; 
import {secretKey} from '../../../../config/env';
import { Card, Col } from 'react-bootstrap';


import { format, parseISO } from 'date-fns';
import { VccService } from '../../../../services/VccService';
import MyWallet from './MyWallet';
import AddTopup from './AddTopup';


const VccCardDetail = () => {
    const { Id } = useParams();
    const [data, setData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState("Success");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [balance, setBalance] = useState([]);
    const [dateTime, setdateTime] = useState([]);

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (Id) {
            VccService.getVccCard(Id)
                 .then((response) => {
                    const { data } = response.data;
                    if (data) {
                        setData(data[0]);                        
                        // Directly set the data object
                        const dateOnly = format(parseISO(data[0].card.cardExpiry.date), "yyyy/MM");
                        setdateTime(dateOnly);

                    }  else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);


    const handleAdd = () => {
        setModalOpen(true); // Open the modal
    
    };




const handleBackToList = () => {
  navigate(`/${secretKey}/vcc-list`); // Replace with the path to your list view
};



    return (

        <Fragment>
        { data? (
            <Col xl='12'>
              <MyWallet 
              balance={data.card.cardBalance}
              handleAdd={handleAdd}
              clientname={data.client.prenom}
              clientpre={data.client.nom}
              date={dateTime}
              cardId={data.id}
              Id={Id}
              walletBalance={data.walletBalance}
              />
              <Card>
              {isModalOpen && (
                            <AddTopup
                                isOpen={isModalOpen} 
                                onClose={() => {
                                    setModalOpen(false);
                                }}
                                email={data.client.email}
                            />
                        )}
                  <Card.Header>


                  </Card.Header>
                  <Card.Body>
                  <Card.Text>
                      <h4><b>Card Id : </b></h4>  <h5>{data.id}</h5><hr/>
                      <h4><b>Card Number : </b></h4>  <h5>{data.card.cardNumber}</h5><hr/>
                      <h4><b>User : </b></h4>  <h5>{data.client.email}</h5><hr/>
                      <h4><b>Card Expiry Date : </b></h4>  <h5>{dateTime}</h5><hr/>

                  </Card.Text>
                  </Card.Body>
                  <Card.Footer className=' d-sm-flex justify-content-between align-items-center'>
                  <Card.Text className=' text-dark d-inline'>
                      {/* Last updated 3 mins ago */}
                  </Card.Text>
               
                  <button className='btn btn-secondary ms-2' onClick={handleBackToList}>
                          Back to List
                      </button>
                  </Card.Footer>
              </Card>
              </Col>
        ):(

          <div className="">no data</div>
        )
      }
      </Fragment>
    );
};

export default VccCardDetail;
