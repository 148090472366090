import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../services/AuthService'; 
import {secretKey} from '../../../config/env';
import { CardsService } from '../../../services/CardsService';
import { Card, Col, Tab } from 'react-bootstrap';
import MasterWallet from '../MasterWalletBalance/MasterWallet';
import AddTopup from './AddTopup';
import MyWallet from './MyWallet';
import { format, parseISO } from 'date-fns';
import { StatisticsService } from '../../../services/StatisticsService';

const ClientCardDetails = () => {
    const { Id } = useParams();
    const [data, setData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState("Success");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [balance, setBalance] = useState([]);
    const [dateTime, setdateTime] = useState([]);

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (Id) {
            CardsService.getById(Id)
                 .then((response) => {
                    const { data } = response.data;
                    if (data) {
                        setData(data[0]);                        
                        const dateOnly = format(parseISO(data[0].issueDate), "yyyy-MM-dd");
                        setdateTime(dateOnly);

                    }  else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);


    useEffect(() => {
      // You can modify the service method to accept page number and items per page
      CardsService.getBalancebyId(Id) // API expects 1-based index, so add 1
          .then((response) => {
              const { data } = response.data;
              
              // Calculate total pages
             
              
              setBalance(data.balance);

          })
          .catch((error) => {
              console.error('Error fetching data:', error);
          });
  }, []); // Re-






    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        // Add save logic here
        setIsEditing(false);
        const isConfirmed = window.confirm("Are you sure you want to save this edit?");

        const dataToSend = {
          amount: data.amount,
          hashTransaction: data.hashTransaction,
      };

        if (isConfirmed) {
            CardsService.editRequestDeposit(Id,dataToSend)
          .then(response => {
            swal("Good job!", "data edited successfully", "success")
            // window.location.reload();
          })
          .catch(error => {
            const errorMessage = error.response?.data.message || 'An unexpected error occurred';
            console.error('Error edit:', errorMessage);
            swal("Ooops!", errorMessage, "error");
        });
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // Optionally reset form data if necessary
    };

    const handleBack = () => {
      navigate(`/${secretKey}/client-deposit-request`);
    };


    const handleApproveRequest = () => {
      const isConfirmed = window.confirm("Are you sure you want to approve this request?");
      if (isConfirmed) {
        CardsService.approveRequestDeposit(Id,{ status: approvalStatus })
        .then(response => {
          swal("Good job!", "Deposit Request Approved Successfully", response);
          window.location.reload();
        })
        .catch(error => {
          const errorMessage = error.response?.data.message || 'An unexpected error occurred';
          console.error('Error approving request:', errorMessage);
          swal("Ooops!", errorMessage, "error");
      });
      }
  };
  const handleAdd = () => {
    setModalOpen(true); // Open the modal

};


const handleBackToList = () => {
  navigate(`/${secretKey}/Cards`); // Replace with the path to your list view
};

const suspend = () => {
  const isConfirmed = window.confirm("Are you sure you want to suspend this card?");

  if (isConfirmed) {
    CardsService.suspend(Id,{ suspend: "yes" })
    .then(response => {
      swal("Good job!", "card suspended successfully", "success")
      .then(() => {
        // Redirect to another page
        window.location.href = `/${secretKey}/Cards`; // Replace with your desired route
    });
    })
    .catch(error => {
      const errorMessage = error.response?.data.message || 'An unexpected error occurred';
      swal("Ooops!", errorMessage, "error");
  });
  }    
};
const suspendapi = () => {
  const isConfirmed = window.confirm("Are you sure you want to suspend this card?");

  if (isConfirmed) {
    CardsService.suspendapi(Id,{ suspend: "yes" })
    .then(response => {
      swal("Good job!", "card suspended successfully", "success")
      .then(() => {
        // Redirect to another page
        window.location.href = `/${secretKey}/Cards`; // Replace with your desired route
    });
    })
    .catch(error => {
      const errorMessage = error.response?.data.message || 'An unexpected error occurred';
      swal("Ooops!", errorMessage, "error");
  });
  }    
};
const unsuspend = () => {
  const isConfirmed = window.confirm("Are you sure you want to unsuspend this card?");

  if (isConfirmed) {
    CardsService.suspend(Id,{ suspend: "no" })
    .then(response => {
      swal("Good job!", "card activated successfully", "success")
      .then(() => {
                      // Redirect to another page
        window.location.href = `/${secretKey}/Cards`; // Replace with your desired route

      });
    })
    .catch(error => {
      const errorMessage = error.response?.data.message || 'An unexpected error occurred';
      swal("Ooops!", errorMessage, "error");
  });
  }    
};
const unsuspendapi = () => {
  const isConfirmed = window.confirm("Are you sure you want to unsuspend this card?");

  if (isConfirmed) {
    CardsService.suspendapi(Id,{ suspend: "no" })
    .then(response => {
      swal("Good job!", "card activated successfully", "success")
      .then(() => {
        // Redirect to another page
        window.location.href = `/${secretKey}/Cards`; // Replace with your desired route
    });
    })
    .catch(error => {
      const errorMessage = error.response?.data.message || 'An unexpected error occurred';
      swal("Ooops!", errorMessage, "error");
  });
  }    
};

    return (

        <Fragment>
        { data? (
            <Col xl='12'>


              <MyWallet 
              balance={balance}
              handleAdd={handleAdd}
              clientname={data.client.prenom}
              clientpre={data.client.nom}
              date={dateTime}
              cardId={data.id}
              Id={Id}
              walletBalance={data.walletBalance}
              walletBalanceCix={data.walletBalanceCix}
              />
              <Card>

              {isModalOpen && (
                            <AddTopup
                                isOpen={isModalOpen} 
                                onClose={() => {
                                    setModalOpen(false);
                                }}
                                email={data.client.email}
                            />
                        )}
                  <Card.Header>
                  {/* <button className="btn btn-secondary" type="button" onClick={handleAdd}>add top up request</button> */}
               
                  <button className='btn btn-secondary ms-2' onClick={handleBackToList}>
                          Back to List
                      </button>
                      {data.suspendStatusAdmin === false ?
                      <button className="btn btn-danger" onClick={suspendapi}>Suspend Admin</button>
                      :(
                        <button className="btn btn-primary" onClick={unsuspendapi}>Unsuspend Admin</button>

                      )}

                      {(data.suspendStatusClient === false)?
                      <button className="btn btn-danger" onClick={suspendapi}>Suspend</button>
                      :(
                        <button className="btn btn-primary" onClick={unsuspendapi}>Unsuspend</button>
                      )}
                  </Card.Header>
                  <Card.Body>
                  <Card.Text>
                      <h4><b>Card Id : </b></h4>  <h5>{data.id}</h5><hr/>
                      <h4><b>User : </b></h4>  <h5>{data.client.email}</h5><hr/>
                      <h4><b>Balance limit : </b></h4> <h5> {data.balanceLimit}</h5><hr/>
                      <h4><b>Credit limit : </b></h4> <h5> {data.creditLimit}</h5><hr/>
                      <h4><b>Currency : </b></h4> <h5> {data.currency}</h5><hr/>

                      <h4><b>Status: </b></h4><h5>
                      {
                      (data.suspendStatusAdmin === false && data.suspendStatusClient === false) ? 
                      (<span className='badge badge-success'>Active</span>):(
                         (data.suspendStatusAdmin === true  && data.suspendStatusClient === true) ?
                        (

                          <span  className='badge badge-danger'>Suspended</span>
                        ):
                         data.suspendStatusClient === true ? (
                          <span  className='badge badge-danger'>Card Suspended by client</span> )
                          
                          :
                          (

                              <span  className='badge badge-danger'>Suspended by admin</span>

                          )
                        
                        
                        )
                      }
                      </h5>
                      
                      <hr/>
                      
                      
                      {/* <h4><b>Status: </b></h4><h5>{data.suspendStatusAdmin === false? (<span className='badge badge-success'>Active</span>):(<span  className='badge badge-danger'>Suspended</span>)}</h5><hr/> */}

                      <h4><b>Issue Date : </b></h4>  <h5>{dateTime}</h5><hr/>

                  </Card.Text>
                  </Card.Body>
                  <Card.Footer className=' d-sm-flex justify-content-between align-items-center'>
                  <Card.Text className=' text-dark d-inline'>
                      {/* Last updated 3 mins ago */}
                  </Card.Text>

                  </Card.Footer>
              </Card>
              </Col>
        ):(

          <div className="">no data</div>
        )
      }
      </Fragment>
    );
};

export default ClientCardDetails;
