import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Card, Col } from 'react-bootstrap';
import swal from "sweetalert";
import { LoadRequestService } from '../../../services/LoadRequestService';
import MasterWallet from '../MasterWalletBalance/MasterWallet';
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../services/AuthService'; 
import {secretKey} from '../../../config/env';

const LoadRequestDetails = () => {
    const { Id } = useParams();
    const [loadData, setLoadData] = useState(null);
    const [approvalStatus, setApprovalStatus] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (Id) {
          LoadRequestService.showLoadRequestById(Id)
                 .then((response) => {
                    const { data } = response.data;
                    if (data) {
                        setLoadData(data[0]); 
                    }  
                    else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);
    const handleApprove = () => {
      
      const isConfirmed = window.confirm("Are you sure you want to approve this request?");
      if (isConfirmed) {
        setApprovalStatus("Success");
          LoadRequestService.approveLoadRequestById(Id,{ status: approvalStatus })
              .then(response => {
                  console.log('Approval successful:', response.message);
                  swal("Good job!", "load request approved successfully", "success")
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
                    swal("Ooops!", errorMessage, "error");
                });
      }
  };

  const handleBackToList = () => {
    navigate(`/${secretKey}/top-up-request`); // Replace with the path to your list view
};
    return (

        <Fragment>
          { loadData? (
              <Col xl='12'>
                <Card>
                    <Card.Header>
                    <Card.Title>Load request with id : {loadData.id} </Card.Title>
                    <MasterWallet/>
                    </Card.Header>
                    <Card.Body>
                    <Card.Text>
                        <h4><b>User : </b></h4>  <h5>{loadData.user.email}</h5><hr/>
                        <h4><b>Amount Fiat : </b></h4> <h5>{loadData.amountFiat} EUR</h5><hr/>
                        <h4><b>Amount Crypto Total : </b></h4> <h5>{loadData.amountCryptoTotal} USDT</h5><hr/>
                        <h4><b>Status : </b></h4><h5>{loadData.status}</h5><hr/>
                        <h4><b>Payment Method : </b></h4> <h5>{loadData.paymentMethod}</h5>

                    </Card.Text>
                    </Card.Body>
                    <Card.Footer className=' d-sm-flex justify-content-between align-items-center'>
                    <Card.Text className=' text-dark d-inline'>
                        {/* Last updated 3 mins ago */}
                    </Card.Text>
                    {loadData.status === 'Success' ? (
                            <span className="badge bg-success" style={{ float: 'right' }}>Approved</span>
                        ) : (
                            <button className="btn btn-primary" style={{ float: 'right' }} type="button" onClick={handleApprove}>
                                Approve Request
                            </button>
                        )}  

                    <button className='btn btn-secondary ms-2' onClick={handleBackToList}>
                            Back to List
                        </button>
                    </Card.Footer>
                </Card>
                </Col>
          ):(

            <div className="">no data</div>
          )
        }
        </Fragment>
    );
};

export default LoadRequestDetails;
