import React, { useState, Fragment } from 'react';
import { Modal } from "react-bootstrap";
import { LoadRequestService } from '../../../../services/LoadRequestService';
import swal from "sweetalert";
import {secretKey} from '../../../../config/env';

const AddTopup = ({ isOpen, onClose, data, email }) => {
    const [formData, setFormData] = useState(data); // Initialize form data with the passed data

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const topupData = {
            emailUser: email,
            amountFiat: formData.amount,
        }
        LoadRequestService.addLoadRequest(topupData)                 
        .then((response) => {
            swal("Good job!", "Topup added successfully", "success")
            .then(() => {
                // Redirect to another page
                window.location.href = `/${secretKey}/pending-topup-requests`; // Replace with your desired route
            });
        })
            .catch(error => {
                const errorMessage = error.response?.data.message || 'An unexpected error occurred';
                swal("Ooops!", errorMessage, "error");
        })
        onClose();
    };

    return (
        <Fragment>
            <Modal className="modal fade" show={isOpen} onHide={onClose}>
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Parameters</h5>
                            <button type="button" className="close" onClick={onClose}><span>&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {/* Form for editing */}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="email">email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        value={email}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="amount">Amount</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="amount"
                                        name="amount"
                                        onChange={handleInputChange}
                                    />
                                </div>                             
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={onClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save changes</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default AddTopup;