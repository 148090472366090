import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams, Navigate } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Error404 from './jsx/pages/Error404';
import {secretKey} from './config/env';
// const secretSegment = '123456';

// function PrivateRoute({ element }) {
//   const currentPath = window.location.pathname;
//   // Check if the current URL includes the secret segment
//   if (!currentPath.includes(secretSegment)) {
//     // Redirect to a "Not Found" page or deny access if the secret isn't found
//     return <Navigate to="/404" />;
//   }
//   return element;
// }
const isSecretKeyPresent = window.location.pathname.includes(secretKey);


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
	
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function App (props) {
    const dispatch = useDispatch();
	  const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
       checkAutoLogin(dispatch, navigate);
    }, []);

    useEffect(() => {
      if (props.isAuthenticated && location.pathname === `/${secretKey}/login`) {
          navigate(`/${secretKey}/dashboard`);
      }
  }, [props.isAuthenticated, location.pathname, navigate]);

    let routeblog = (  
             
      <Routes>
      <Route path={`/${secretKey}/login`} element={<Login />} />
      <Route path={`/${secretKey}/`} element={<Navigate to={`/${secretKey}/login`} />} />

  </Routes> 
    );
    let routeblog2 = (
       <Routes>
          <Route path="/page-error-404" element={<Error404 />} />
          <Route path="*" element={<Navigate to="/page-error-404" />} />
        </Routes> 

    );
    if (props.isAuthenticated) {
		return (
			<>
          <Suspense fallback={
              <div id="preloader">
                  <div className="sk-three-bounce">
                      <div className="sk-child sk-bounce1"></div>
                      <div className="sk-child sk-bounce2"></div>
                      <div className="sk-child sk-bounce3"></div>
                  </div>
              </div>  
              }
          >
            <Index /> 
          </Suspense>
      </>
  );
	
	}else{
		return (
			<div className="vh-100">
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
              }
            >
                {isSecretKeyPresent ? (

                routeblog)
                : (
                  routeblog2
                  )}
            </Suspense>
			</div>
		);
	}
};


const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 