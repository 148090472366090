import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { useNavigate } from 'react-router-dom';
import {secretKey} from '../../../config/env';


export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header: 'Full Name',
		Footer: 'Full Name',
		accessor: (row) => ` ${row.client.prenom} ${row.client.nom}`, // Combine first and last names
		id: 'clientName', // Set an id to prevent accessor conflicts
		Filter: ColumnFilter,
	},
	{
		Header : 'username',
		Footer : 'username',
		accessor: 'client.email',
		Filter: ColumnFilter,
	},

	// {
	// 	Header: 'Status Admin',
	// 	Footer: 'Status Admin',
	// 	accessor: 'suspendStatusAdmin',
	// 	Filter: ColumnFilter,
	// 	Cell: ({ value }) => {
	// 		const isActive = value === false;
	// 		const badgeClass = isActive ? 'badge-success' : 'badge-danger';
	// 		const displayText = isActive ? 'Active' : 'Suspended';
	
	// 		return (
	// 			<span className={`badge ${badgeClass}`}>
	// 				{displayText}
	// 			</span>
	// 		);
	// 	},
	// },
	// {
	// 	Header: 'Status Api',
	// 	Footer: 'Status Api',
	// 	accessor: 'suspendStatusApi',
	// 	Filter: ColumnFilter,
	// 	Cell: ({ value }) => {
	// 		const isActive = value === false;
	// 		const badgeClass = isActive ? 'badge-success' : 'badge-danger';
	// 		const displayText = isActive ? 'Active' : 'Suspended';
	
	// 		return (
	// 			<span className={`badge ${badgeClass}`}>
	// 				{displayText}
	// 			</span>
	// 		);
	// 	},
	// },
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status', // Virtual accessor, computed dynamically
		Filter: ColumnFilter,
		Cell: ({ row }) => {
			const cardStatusAdmin = row.original.suspendStatusAdmin;
			const cardStatusClient = row.original.suspendStatusClient;
			let displayText;
			let badgeClass;
			if (cardStatusAdmin === true && cardStatusClient === true) {
				displayText = 'Suspended ';
				badgeClass = 'badge-danger';
			}
			else if (cardStatusAdmin === true) {
				displayText = 'Suspended by Admin';
				badgeClass = 'badge-danger';
			} else if (cardStatusClient === true) {
				displayText = 'Suspended by Client';
				badgeClass = 'badge-danger';
			} else {
				displayText = 'Active';
				badgeClass = 'badge-success';
			}
	
			return (
				<span className={`badge ${badgeClass}`}>
					{displayText}
				</span>
			);
		},
	},
	
    {
        Header: 'Action',
        Footer: 'Action',
        accessor: 'Id', // Assuming `id` is the unique identifier for the row
        Cell: ({ row }) => {
            const navigate = useNavigate();
            const handleShow = () => {
                navigate(`/${secretKey}/client-card-details/${row.original.id}`);
            };

            return (
                <div>
                    <button className="btn btn-primary" onClick={handleShow}>Show</button>
                </div>
            );
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Transaction',
        Footer: 'Transaction',
        accessor: 'cardId', // Assuming `id` is the unique identifier for the row
        Cell: ({ row }) => {
            const navigate = useNavigate();
            const handleShow = () => {
                navigate(`/${secretKey}/transaction/${row.original.id}`);
            };

            return (
                <div>
                    <button className="btn btn-secondary" onClick={handleShow}>View</button>
                </div>
            );
        },
        Filter: ColumnFilter,
    },
	{
        Header: 'Subscription',
        Footer: 'Subscription',
        accessor: 'cardSubId', // Assuming `id` is the unique identifier for the row
        Cell: ({ row }) => {
            const navigate = useNavigate();
            const handleShow = () => {
                navigate(`/${secretKey}/subscription-bycard/${row.original.id}`);
            };

            return (
                <div>
                    <button className="btn btn-warning" onClick={handleShow}>Check</button>
                </div>
            );
        },
        Filter: ColumnFilter,
    },
]



export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]