import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { WalletService } from '../../../services/WalletService';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../services/AuthService'; 
import {secretKey} from '../../../config/env';

const Deposit = () => {
    const { Id } = useParams();
    const [data, setData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [approvalStatus] = useState("Success");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (Id) {
            WalletService.getLoadBy(Id)
                 .then((response) => {
                    const { data } = response.data;
                    if (data) {
                        setData(data[0]);  
                        // Directly set the data object
                    }  else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);



    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        // Add save logic here
        setIsEditing(false);
        const isConfirmed = window.confirm("Are you sure you want to save this edit?");

        const dataToSend = {
        
          amountFiat: data.amountFiat,
          amountCrypto: data.amountCrypto,
          fees:data.fees,
          transationHash: data.transationHash,
          approvalDate: data.approvalDate.date,
          requestDateAt: data.requestDate.date,
          status: data.status,

      };

        if (isConfirmed) {
          WalletService.editLoad(dataToSend,data.id)
          .then(response => {
            swal("Good job!", "data edited successfully", "success").then(() => {

                window.location.reload();
    
              });
            // window.location.reload();
          })
          .catch(error => {
            const errorMessage = error.response?.data.message || 'An unexpected error occurred';
            console.error('Error edit:', errorMessage);
            swal("Ooops!", errorMessage, "error");
        });
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // Optionally reset form data if necessary
    };

    const handleBack = () => {
      navigate(`/${secretKey}/topup-wallet`);
    };





    const handleApproveRequest = () => {
      const isConfirmed = window.confirm("Are you sure you want to approve this request?");
      if (isConfirmed) {
        WalletService.approveLoad(data.id,{ status: approvalStatus })
        .then(response => {
          swal("Good job!", "Request Approved Successfully", "success").then(() => {

            window.location.reload();

          });
        })
        .catch(error => {
          const errorMessage = error.response?.data.message || 'An unexpected error occurred';
          console.error('Error approving request:', errorMessage);
          swal("Ooops!", errorMessage, "error");
      });
      }
  };

    return (

        <Fragment>
         

         { data? (
          
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Top Up Detail</h4>
                <button className="btn btn-secondary" type="button" onClick={handleBack}>Back to list</button>
  
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Fiat Amount</label>
                        <input
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`}
                          name="amountFiat"                   
                          type="number"
                          value={data.amountFiat}
                          disabled={!isEditing}
                          onChange={(e) => setData({ ...data, amountFiat: e.target.value })}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Crypto Amount</label>
                         <input
                          type="number"
                          name="amountCrypto"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={data.amountCrypto}
                          disabled={!isEditing}
                          onChange={(e) => setData({ ...data, amountCrypto: e.target.value })}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Fees</label>
                         <input
                          type="number"
                          name="fees"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={data.fees}
                          disabled={!isEditing}
                          onChange={(e) => setData({ ...data, fees: e.target.value })}                        />
                      </div>    
                      <div className="form-group mb-3 col-md-6">
                        <label>Transaction Hash</label>
                         <input
                          type="text"
                          name="transationHash"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={data.transationHash}
                          disabled={!isEditing}
                          onChange={(e) => setData({ ...data, transationHash: e.target.value })}
                        />
                      </div>
                      
                      
                  </div>
                  <div className="row">
                  <div className="form-group mb-3 col-md-6">
    <label>Approval Date</label>
    <input
        type="date"
        name="approvalDate"
        className={`form-control ${!isEditing ? 'disabled-input' : ''}`}
        value={data.approvalDate?.date ? data.approvalDate.date.split(" ")[0] : ""} // Extracting the date
        disabled={!isEditing}
        onChange={(e) =>
            setData({ ...data,
               approvalDate: { date: `${e.target.value} 00:00:00`
              }})
        }
    />
</div>
<div className="form-group mb-3 col-md-6">
    <label>Requested Date</label>
    <input
        type="date"
        name="requestDate"
        className={`form-control ${!isEditing ? 'disabled-input' : ''}`}
        value={data.requestDate?.date ? data.requestDate.date.split(" ")[0] : ""} // Extracting the date
        disabled={!isEditing}
        onChange={(e) =>
          setData({
            ...data,
            requestDate: { date: `${e.target.value} 00:00:00` } }) }
    />
</div></div>
<div className="form-group mb-3 col-md-6">
    <label>Status</label>
    <select
        name="status"
        className={`form-control ${!isEditing ? 'disabled-input' : ''}`}
        value={data.status}
        disabled={!isEditing}
        onChange={(e) => setData({ ...data, status: e.target.value })}
    >
        <option value="Success">Success</option>
        <option value="Pending">Pending</option>
        <option value="Declined">Declined</option>
    </select>
</div>

                  <div>
                  {!isEditing ? (
                          <button className="btn btn-primary" type="button" onClick={handleEditClick}>Edit</button>
                      ) : (
                          <>
                              <button className="btn btn-primary" type="button" onClick={handleSaveClick}>Save</button>
                              <button className="btn btn-primary" type="button" onClick={handleCancelClick}>Cancel</button>
                          </>
                      )}
                        {data.status === 'Success' ? (
                              <span className="badge bg-success" style={{ float: 'right' }}>Approved</span>
                          ) : (
                              <button className="btn btn-secondary" style={{ float: 'right' }} type="button" onClick={handleApproveRequest}>
                                  Approve Request
                              </button>
                          )}                                  
                        </div>
                        
                  </form>
                </div>
              </div>
            </div>
          </div>
            ):(<div className="">no data</div>)
          }


        </Fragment>
    );
};

export default Deposit;
