import axios from 'axios';
import swal from "sweetalert";
import { jwtDecode } from 'jwt-decode';
import {  API_URL } from '../config/env';


import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';


export function signUp(email, password) {
    //axios call
    // const postData = {
    //     email,
    //     password,
    //     returnSecureToken: true,
    // };
    // return axios.post(
    //     `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    //     postData,
    // );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        // returnSecureToken: true,
    };
    return axios.post(
        `${API_URL}/admin/login`,
        postData,
    );
}

export function formatError(errorResponse) {
    console.log(errorResponse);
    switch (errorResponse.message) {
        case 'Invalid credentials':
            //return 'Invalid Password';
            swal("Oops", "Invalid Email or Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    const token = tokenDetails.token;
    const [header, payload, signature] = token.split('.');

    localStorage.setItem('Header', header);
    localStorage.setItem('payload', payload);
    localStorage.setItem('sig', signature);

}

export function checkAutoLogin(dispatch, navigate) {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');
    const token = `${tokenHeader}.${tokenPayload}.${tokenSignature}`;

    if (!tokenHeader || !tokenPayload || !tokenSignature) {
        // If any part of the token is missing, log the user out
        dispatch(Logout(navigate));
        return;
    }

    try {
        // Decode the token payload to get expiration time
        const decodedToken = jwtDecode(token);

        // Get the current time in seconds
        const currentTime = Date.now() / 1000;

        // Check if the token is expired
        if (decodedToken.exp && decodedToken.exp < currentTime) {
            dispatch(Logout(navigate)); // Token has expired, log the user out
            return;
        }

        // If the token is valid, dispatch the login action
        const tokenDetails = { token };
        dispatch(loginConfirmedAction(tokenDetails));

    } catch (error) {
        console.error("Invalid token or error in decoding:", error);
        dispatch(Logout(navigate)); // Invalid token, log the user out
    }
}
