
// export default TransferBarGraph;
// import React from "react";
// import ReactApexChart from "react-apexcharts";
// import { StatisticsService } from "../../../../services/StatisticsService";

// class TransferBarGraph extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [
//         {
//           name: 'Deposit',
//           data: [], // Initialize as empty
//         },
//         {
//           name: 'Top up',
//           data: [], // Initialize as empty
//         },
//         {
//           name: 'Fees', // New data series for fees
//           data: [],
//         },
//       ],
//       options: {
//         chart: {
//           height: 370,
//           type: "bar",
//           toolbar: {
//             show: false,
//           },
//         },
//         plotOptions: {
//           bar: {
//             borderRadius: 8,
//             horizontal: false,
//             columnWidth: '57%',
//           },
//         },
//         colors: ['#D2D2D2', '#EBEBEB', '#FF6347'], // Added a color for Fees
//         legend: {
//           show: true,
//           fontSize: '12px',
//           labels: {
//             colors: '#000000',
//           },
//           markers: {
//             width: 18,
//             height: 18,
//             strokeWidth: 0,
//             strokeColor: '#fff',
//             radius: 12,
//           },
//         },
//         fill: {
//           opacity: 1,
//           colors: ['#D2D2D2', '#EBEBEB', '#FF6347'], // Added color for Fees
//         },
//         dataLabels: {
//           enabled: false,
//         },
//         stroke: {
//           show: true,
//           width: 4,
//           colors: ['transparent'],
//         },
//         grid: {
//           borderColor: '#eee',
//         },
//         xaxis: {
//           categories: [], // Empty initially, will be populated from the service
//           labels: {
//             style: {
//               colors: '#787878',
//               fontSize: '13px',
//               fontFamily: 'poppins',
//               fontWeight: 100,
//             },
//           },
//           crosshairs: {
//             show: false,
//           },
//         },
//         yaxis: {
//           labels: {
//             offsetX: -16,
//             style: {
//               colors: '#787878',
//               fontSize: '13px',
//               fontFamily: 'poppins',
//               fontWeight: 100,
//             },
//             formatter: function (value) {
//               return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
//             },
//           },
//         },
//         tooltip: {
//           y: {
//             formatter: function (val, { seriesIndex }) {
//               const roundedValue = Math.round(val); // Round tooltip values
//               if (seriesIndex === 0) {
//                 return "₮"+ roundedValue  ; // Deposit series
//               } else {
//                 return "€" + roundedValue  ; // Top up and Fees series
//               }
//             },
//           }
//         },
//       },
//     };
//   }

//   componentDidMount() {
//     // Fetch data from the StatisticService
//     Promise.all([
//       StatisticsService.getTransactionMetric(),
//     ])
//       .then(([transactionResponse]) => {
//         // Extract totalAmountDepositbyMonth and totalAmountTopupbyMonth from the transaction API
//         const { totalAmountDepositbyMonth, totalAmountTopupbyMonth, totalTopupFeeByMonth } =
//           transactionResponse.data.data;
//           console.log("response",transactionResponse);
//         console.log("deposit",totalAmountDepositbyMonth);
//         console.log("topup",totalAmountTopupbyMonth);
//         console.log("fees",totalTopupFeeByMonth);

//         const categories = [];
//         const depositData = [];
//         const topupData = [];
//         const feesData = []; // Data for Fees

//         totalAmountDepositbyMonth.forEach((item, index) => {
//           categories.push(item.label); // Use month label as the category

//           // Deposit data
//           depositData.push(item.value || 0);

//           // Top-up data
//           const topupItem = totalAmountTopupbyMonth[index];
//           topupData.push(topupItem?.value?.totalAmountFiat || 0);

//           const topupfeesItem = totalTopupFeeByMonth[index];
//           feesData.push(topupfeesItem?.value?.totalAmountFeeTopup || 0);
//         });

//         // Extract Fees data from the fees API response

//         // Update the state with the prepared data
//         this.setState({
//           series: [
//             { name: 'Deposit', data: depositData },
//             { name: 'Top up', data: topupData },
//             { name: 'Fees', data: feesData },
//           ],
//           options: {
//             ...this.state.options,
//             xaxis: {
//               ...this.state.options.xaxis,
//               categories: categories, // Set months as categories
//             },
//           },
//         });
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
//   }

//   render() {
//     return (
//       <div id="chart">
//         <ReactApexChart
//           options={this.state.options}
//           series={this.state.series}
//           type="bar"
//           height={350}
//         />
//       </div>
//     );
//   }
// }

// export default TransferBarGraph;
import React from "react";
import ReactApexChart from "react-apexcharts";
import { StatisticsService } from "../../../../services/StatisticsService";

class TransferBarGraph extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      series: [
        {
          name: "Deposit",
          data: [],
        },
        {
          name: "Top up",
          data: [],
        },
        {
          name: "Fees",
          data: [],
        },
      ],
      options: {
        chart: {
          height: 370,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            horizontal: false,
            columnWidth: "57%",
          },
        },
        colors: ["#D2D2D2", "#EBEBEB", "#FF6347"],
        legend: {
          show: true,
          fontSize: "12px",
          labels: {
            colors: "#000000",
          },
          markers: {
            width: 18,
            height: 18,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
          },
        },
        fill: {
          opacity: 1,
          colors: ["#D2D2D2", "#EBEBEB", "#FF6347"],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ["transparent"],
        },
        grid: {
          borderColor: "#eee",
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#787878",
              fontSize: "13px",
              fontFamily: "poppins",
              fontWeight: 100,
            },
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            offsetX: -16,
            style: {
              colors: "#787878",
              fontSize: "13px",
              fontFamily: "poppins",
              fontWeight: 100,
            },
            formatter: function (value) {
              return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val, { seriesIndex }) {
              const roundedValue = Math.round(val);
              if (seriesIndex === 0) {
                return "₮" + roundedValue;
              } else {
                return "€" + roundedValue;
              }
            },
          },
        },
      },
      allData: null, // Store all data to filter later
      selectedMonth: "", // Selected month for filtering
    };
  }
 
  componentDidMount() {
    StatisticsService.getTransactionMetric()
      .then((transactionResponse) => {
        const {
          totalAmountDepositbyMonth,
          totalAmountTopupbyMonth,
          totalTopupFeeByMonth,
        } = transactionResponse.data.data;
 
        const allData = totalAmountDepositbyMonth.map((item, index) => ({
          month: item.label,
          deposit: item.value || 0,
          topup: totalAmountTopupbyMonth[index]?.value?.totalAmountFiat || 0,
          fees: totalTopupFeeByMonth[index]?.value?.totalAmountFeeTopup || 0,
        }));
 
        this.setState(
          {
            allData, // Store all data for filtering
          },
          () => this.updateChart(allData) // Initially show all data
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
 
  // Function to update chart data based on filter
  updateChart(data) {
    const categories = data.map((item) => item.month);
    const depositData = data.map((item) => item.deposit);
    const topupData = data.map((item) => item.topup);
    const feesData = data.map((item) => item.fees);
 
    this.setState({
      series: [
        { name: "Deposit", data: depositData },
        { name: "Top up", data: topupData },
        { name: "Fees", data: feesData },
      ],
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: categories,
        },
      },
    });
  }
 
  // Handle month selection
  handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    this.setState({ selectedMonth });
 
    // Filter data for the selected month or show all
    const filteredData =
      selectedMonth === ""
        ? this.state.allData
        : this.state.allData.filter((item) => item.month === selectedMonth);
 
    this.updateChart(filteredData);
  };
 
  render() {
    const months = this.state.allData?.map((item) => item.month) || [];
 
    return (
<div>
        {/* Dropdown for month filter */}
<div style={{ marginBottom: "20px" }}>
<select
            value={this.state.selectedMonth}
            onChange={this.handleMonthChange}
            style={{
              padding: "10px",
              fontSize: "14px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
>
<option value="">All Months</option>
            {months.map((month) => (
<option key={month} value={month}>
                {month}
</option>
            ))}
</select>
</div>
 
        {/* Chart */}
<div id="chart">
<ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
</div>
</div>
    );
  }
}
 
export default TransferBarGraph;

