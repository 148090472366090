import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Card, Col } from 'react-bootstrap';
import swal from "sweetalert";
import MasterWallet from '../MasterWalletBalance/MasterWallet';
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../services/AuthService'; 
import {secretKey} from '../../../config/env';
import { UserService } from '../../../services/UserService';

const UserDetails = () => {
    const { Id } = useParams();
    const [data, setData] = useState(null);
    const [dataDeposit, setDataDeposit] = useState(null);

    const [approvalStatus, setApprovalStatus] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (Id) {
          UserService.getUserById(Id)
                 .then((response) => {
                    const { data } = response.data;
                    if (data) {
                        setData(data[0]);
                    }  
                    else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);

    useEffect(() => {
        if (Id) {
          UserService.getDepositMetric(Id)
                 .then((response) => {
                    const { data } = response.data;
                    if (data) {
                        setDataDeposit(data.totalDepositUser); 
                    }  
                    else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);

  const handleBackToList = () => {
    navigate(`/${secretKey}/Users-list`); // Replace with the path to your list view
};
    return (

        <Fragment>
          { data? (
              <Col xl='12'>
                <Card>
                    <Card.Header>
                    <Card.Title>User id : {data.id} </Card.Title>
                    <button className='btn btn-secondary ms-2' onClick={handleBackToList}>
                            Back to List
                        </button>
                    </Card.Header>
                    <Card.Body>
                    <Card.Text>
                        <h3>Deposit Section:</h3>
                        <h4>Total Deposit Amount : </h4> <h5>{dataDeposit} USDT </h5>
                        <hr/>
                        <h3>Info Section:</h3>
                        <h4>Email : </h4> <h5>{data.email} </h5>
                        <h4>Login Type : </h4> <h5>{data.loginType}</h5>
                        <hr/>
                        <h3>Referal Section:</h3>
                        {data.referralCode[0]?
                        (
                        <div><h4>Referal code : </h4> <h5>{data.referralCode[0].referralCode}</h5>
                        <h4>created at: </h4> <h5>{data.referralCode[0].createdAt.date}</h5>
                        <h4>expires at: </h4> <h5>{data.referralCode[0].expireAt.date}</h5><hr/></div>
                        ):(
                            <div>no referal code for this user</div>
                        )
                         }
                        <h3>Card Section:</h3>
                        <h4>Card : </h4> <h5>{data.card? (<h5>Active Card</h5>):(<h5>No Active Card</h5>)} </h5>
                
                        <hr/>
                        
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer className=' d-sm-flex justify-content-between align-items-center'>
                    <Card.Text className=' text-dark d-inline'>
                        {/* Last updated 3 mins ago */}
                    </Card.Text>
                   

                    </Card.Footer>
                </Card>
                </Col>
          ):(

            <div className="">no data</div>
          )
        }
        </Fragment>
    );
};

export default UserDetails;
